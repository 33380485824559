import {
  AreaPine1,
  AreaPine2,
  AreaPine3,
  AreaPine4,
  AreaA,
  AreaB,
  AreaC,
  AreaD,
  AreaE,
  AreaF,
  AreaG,
  AreaH,
  AreaI,
  AreaJ,
  AreaK,
  AreaL,
  AreaM,
  AreaN,
  AreaO,
  AreaP,
  AreaQ,
  AreaR,
  AreaS,
  AreaT,
  AreaU,
  AreaV,
  AreaW,
  AreaX,
  AreaY,
  AreaZ,
} from "../components/mapSVGs/pineHill/pinePhase1AreaPart";
import { colors } from "./theme";
import {
  P2_0,
  P2_1,
  P2_2,
  P2_3,
  P2_4,
  P2_5,
  P2_6,
  P2_7,
  P2_8,
  P2_9,
  P2_10,
  P2_11,
  P2_12,
  P2_13,
  P2_14,
  P2_15,
  P2_16,
  P2_17,
  P2_18,
  P2_19,
  P2_20,
  P2_21,
  P2_22,
  P2_23,
  P2_24,
  P2_25,
  P2_26,
  P2_27,
  P2_28,
  P2_29,
  P2_30,
} from "../components/mapSVGs/pineHill/pinePhase2AreaPart";
import {
  P3_1,
  P3_2,
  P3_3,
  P3_4,
  P3_5,
  P3_6,
  P3_7,
  P3_8,
  P3_9,
  P3_10,
  P3_11,
  P3_12,
  P3_13,
  P3_14,
  P3_15,
  P3_16,
  P3_17,
  P3_18,
  P3_25,
  P3_26,
  P3_27,
  P3_28,
  P3_29,
  P3_30,
  P3_31,
  P3_32,
  P3_35,
  P3_36,
  P3_37,
  P3_38,
  P3_39,
  P3_40,
  P3_41,
  P3_42,
  P3_43,
  P3_44,
  // P3_45,
  // P3_46,
  P3_48,
  P3_49,
  P3_50,
  P3_51,
  P3_52,
  P3_53,
  P3_54,
  // P3_68,
  // P3_69,
} from "../components/mapSVGs/pineHill/pinePhase3AreaPart";
import {
  // P4_1,
  // P4_2,
  P4_3,
  P4_4,
  P4_5,
  // P4_6,
  // P4_7,
  // P4_8,
  P4_9,
  // P4_10,
  P4_11,
  P4_12,
  P4_13,
  P4_14,
  P4_15,
  P4_16,
  P4_17,
  P4_18,
  P4_19,
  P4_20,
  P4_21,
  P4_22,
  P4_23,
  P4_24,
  P4_25,
  P4_26,
  P4_27,
  P4_28,
  P4_29,
  P4_30,
  P4_31,
  P4_32,
  P4_33,
  P4_34,
  P4_35,
  P4_36,
  P4_37,
  P4_38,
  P4_39,
  P4_40,
  // P4_41,
  // P4_42,
  P4_43,
  P4_44,
  P4_45,
  P4_46,
  P4_47,
  P4_48,
  P4_49,
  // P4_50,
  P4_51,
  P4_52,
  P4_53,
  P4_54,
  P4_55,
  P4_56,
  P4_57,
  // P4_58,
  P4_59,
  P4_60,
  P4_61,
  P4_62,
  P4_63,
  P4_64,
  P4_65,
  P4_66,
  P4_67,
  P4_68,
  P4_69,
  P4_70,
  P4_71,
  P4_72,
  P4_73,
  P4_74,
  P4_75,
  P4_76,
  P4_77,
  P4_78,
  P4_79,
  P4_80,
  P4_81,
  P4_82,
  P4_83,
  P4_84,
  // P4_85,
  // P4_86,
  P4_87,
  // P4_88,
  // P4_89,
  // P4_90,
  // P4_91,
  // P4_92,
  // P4_93,
  // P4_94,
  // P4_95,
  // P4_96,
  // P4_97,
  // P4_98,
  // P4_99,
  // P4_100,
  // P4_101,
} from "../components/mapSVGs/pineHill/pinePhase4AreaPartPrevious";
import {
  C01,
  C02,
  C03,
  C04,
  C05,
  C06,
  C07,
  C08,
  C09,
  C10,
  C11,
  C12,
  C13,
  C14,
  C15,
  C16,
  C17,
  C18,
  C19,
  C20,
  C21,
  C22,
  // C23,
  C24,
  C25,
  C26,
  C27,
  C28,
  // C29,
  C30,
  C31,
  C32,
  C33,
  C34,
  C35,
  C36,
  C37,
  // C38,
  C39,
  // C40,
  // C41,
  C42,
  C43,
  C44,
  C45,
  C46,
  C47,
  C48,
  // C49,
  C50,
  C51,
  C52,
  C53,
  C54,
  C55,
  C56,
  C57,
  C58,
  // C59,
  C60,
  C61,
  C62,
  C63,
  C64,
  C65,
  C66,
  C67,
  C68,
  C69,
  C70,
  C71,
  C72,
  C73,
  M01,
  M02,
  M03,
  // M04,
  M05,
  M06,
  M07,
  // M08,
  M09,
  // M10,
  M11,
  M12,
  // M13,
  M14,
  // M15,
  // M16,
  M17,
  M18,
  M19,
  M20,
  M21,
  M22,
  M23,
  M24,
  M25,
  M26,
  M27,
  M28,
  M29,
  M30,
  M31,
  M32,
  M33,
  // M34,
  M35,
  // M36,
  // M37,
  M38,
  M39,
  // M40,
  M41,
  M42,
  // M43,
  M44,
  M45,
  M46,
  M47,
  // M48,
  M49,
  M50,
  M51,
  M52,
  // M53,
  M54,
  M55,
  M56,
  M57,
  // M58,
  // M59,
  // M60,
  M61,
  M62,
  M63,
  // M64,
  // M65,
  M66,
  M67,
  // M68,
  // M69,
  M70,
  M71,
  M72,
  M73,
  // M77,
  D01,
  D02,
  D03,
  D04,
  D05,
  D06,
  D07,
  D08,
  D09,
  D10,
  D11,
  D12,
  D13,
  D14,
  D15,
  D16,
  D17,
  D18,
  D19,
  D20,
  D21,
  D22,
  D23,
  // D24,
  D25,
  D26,
  D27,
  D28,
  D29,
  D30,
  D31,
  D32,
  D33,
  D34,
  D35,
  D36,
  D37,
  D38,
  D39,
  D40,
  D41,
  D42,
  D43,
  D44,
  D45,
  D46,
  // D47,
  D48,
  D49,
  D50,
  D51,
  D52,
  D53,
  D54,
  D55,
  D56,
  D57,
  D58,
} from "../components/mapSVGs/pineHill/pinePhase4AreaPart";
import {
  C100,
  C101,
  C102,
  C103,
  C104,
  C105,
  C106,
  C107,
  C108,
  C74,
  C75,
  C76,
  C77,
  C78,
  C79,
  C80,
  C81,
  C82,
  C83,
  C84,
  C85,
  C86,
  C87,
  C88,
  C89,
  C90,
  C91,
  C92,
  C93,
  C94,
  C95,
  C96,
  C97,
  C98,
  C99,
} from "../components/mapSVGs/pineHill/pinePhase4NordAreaPart";
const pink = colors.pink;
const yellow = colors.yellow;
const green = colors.green;

const areaSet = [
  {
    x: 1878,
    y: 1006,
    title: "A",
    badge: "",
    ax: 1787,
    ay: 877,
    area: AreaA,
    color: pink,
  },
  {
    x: 2160,
    y: 418,
    title: "B",
    badge: "",
    ax: 2079,
    ay: 320,
    area: AreaB,
    color: pink,
  },
  {
    x: 1954,
    y: 418,
    title: "C",
    badge: "",
    ax: 1870,
    ay: 305,
    area: AreaC,
    color: pink,
  },
  {
    x: 1753,
    y: 424,
    title: "D",
    badge: "",
    ax: 1623,
    ay: 289,
    area: AreaD,
    color: pink,
  },
  {
    x: 1506,
    y: 527,
    title: "E",
    badge: "",
    ax: 1382,
    ay: 415,
    area: AreaE,
    color: pink,
  },
  {
    x: 1310,
    y: 637,
    title: "F",
    badge: "",
    ax: 1153,
    ay: 500,
    area: AreaF,
    color: pink,
  },
  {
    x: 1100,
    y: 758,
    title: "G",
    badge: "",
    ax: 905,
    ay: 651,
    area: AreaG,
    color: green,
  },
  {
    x: 999,
    y: 961,
    title: "H",
    badge: "",
    ax: 905,
    ay: 883,
    area: AreaH,
    color: green,
  },
  {
    x: 1083,
    y: 1200,
    title: "I",
    badge: "",
    ax: 995,
    ay: 1031,
    area: AreaI,
    color: green,
  },
  {
    x: 1150,
    y: 1440,
    title: "J",
    badge: "",
    ax: 1060,
    ay: 1261,
    area: AreaJ,
    color: green,
  },
  {
    x: 1272,
    y: 1659,
    title: "K",
    badge: "",
    ax: 1133,
    ay: 1495,
    area: AreaK,
    color: green,
  },
  {
    x: 1501,
    y: 1608,
    title: "L",
    badge: "",
    ax: 1365,
    ay: 1440,
    area: AreaL,
    color: green,
  },
  {
    x: 1715,
    y: 1466,
    title: "M",
    badge: "",
    ax: 1627,
    ay: 1355,
    area: AreaM,
    color: pink,
  },
  {
    x: 1901,
    y: 1322,
    title: "N",
    badge: "",
    ax: 1770,
    ay: 1195,
    area: AreaN,
    color: pink,
  },
  {
    x: 2072,
    y: 1203,
    title: "O",
    badge: "",
    ax: 1933,
    ay: 1071,
    area: AreaO,
    color: pink,
  },
  {
    x: 2238,
    y: 1073,
    title: "P",
    badge: "",
    ax: 2092,
    ay: 889,
    area: AreaP,
    color: pink,
  },
  {
    x: 2453,
    y: 902,
    title: "Q",
    badge: "",
    ax: 2322,
    ay: 807,
    area: AreaQ,
    color: pink,
  },
  {
    x: 2091,
    y: 857,
    title: "R",
    badge: "",
    ax: 1933,
    ay: 721,
    area: AreaR,
    color: pink,
  },
  {
    x: 1939,
    y: 719,
    title: "S",
    badge: "",
    ax: 1792,
    ay: 671,
    area: AreaS,
    color: green,
  },
  {
    x: 1641,
    y: 771,
    title: "T",
    badge: "",
    ax: 1513,
    ay: 640,
    area: AreaT,
    color: green,
  },
  {
    x: 1477,
    y: 873,
    title: "U",
    badge: "",
    ax: 1350,
    ay: 741,
    area: AreaU,
    color: yellow,
  },
  {
    x: 1310,
    y: 968,
    title: "V",
    badge: "",
    ax: 1171,
    ay: 830,
    area: AreaV,
    color: pink,
  },
  {
    x: 1289,
    y: 1173,
    title: "W",
    badge: "",
    ax: 1171,
    ay: 1031,
    area: AreaW,
    color: pink,
  },
  {
    x: 1407,
    y: 1352,
    title: "X",
    badge: "",
    ax: 1294,
    ay: 1177,
    area: AreaX,
    color: green,
  },
  {
    x: 1578,
    y: 1244,
    title: "Y",
    badge: "",
    ax: 1529,
    ay: 1063,
    area: AreaY,
    color: yellow,
  },
  {
    x: 1728,
    y: 1132,
    title: "Z",
    badge: "",
    ax: 1653,
    ay: 965,
    area: AreaZ,
    color: green,
  },
  { ax: 2275, ay: 620, area: AreaPine1, color: green },
  { ax: 2646, ay: 301, area: AreaPine2, color: green },
  { ax: 2446, ay: 320, area: AreaPine3, color: green },
  { ax: 2275, ay: 336, area: AreaPine4, color: green },
];

const phase2AreaSet = [
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 897,
    ay: 576,
    area: P2_0,
    color: pink,
  },
  {
    x: 960,
    y: 580,
    title: "P2-1",
    badge: "",
    ax: 912,
    ay: 521,
    area: P2_1,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 810,
    ay: 597,
    area: P2_2,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 758,
    ay: 631,
    area: P2_3,
    color: pink,
  },
  {
    x: 810,
    y: 854,
    title: "P2-4",
    badge: "",
    ax: 748,
    ay: 680,
    area: P2_4,
    color: pink,
  },
  {
    x: 755,
    y: 910,
    title: "P2-5",
    badge: "",
    ax: 711,
    ay: 780.89,
    area: P2_5,
    color: pink,
  },
  {
    x: 666,
    y: 915,
    title: "P2-6",
    badge: "",
    ax: 644,
    ay: 847,
    area: P2_6,
    color: pink,
  },
  {
    x: 573,
    y: 941,
    title: "P2-7",
    badge: "",
    ax: 535,
    ay: 872,
    area: P2_7,
    color: pink,
  },
  {
    x: 462,
    y: 937,
    title: "P2-8",
    badge: "",
    ax: 403,
    ay: 859,
    area: P2_8,
    color: pink,
  },
  {
    x: 359,
    y: 915,
    title: "P2-9",
    badge: "",
    ax: 211,
    ay: 801,
    area: P2_9,
    color: pink,
  },
  {
    x: 303,
    y: 838,
    title: "P2-10",
    badge: "",
    ax: 130,
    ay: 741,
    area: P2_10,
    color: pink,
  },
  {
    x: 254,
    y: 758,
    title: "P2-11",
    badge: "",
    ax: 135,
    ay: 666,
    area: P2_11,
    color: pink,
  },
  {
    x: 249,
    y: 677,
    title: "P2-12",
    badge: "",
    ax: 142,
    ay: 599,
    area: P2_12,
    color: pink,
  },
  {
    x: 209,
    y: 590,
    title: "P2-13",
    badge: "",
    ax: 147,
    ay: 535,
    area: P2_13,
    color: pink,
  },
  {
    x: 225,
    y: 499,
    title: "P2-14",
    badge: "",
    ax: 153,
    ay: 444,
    area: P2_14,
    color: pink,
  },
  {
    x: 238,
    y: 381,
    title: "P2-15",
    badge: "",
    ax: 161,
    ay: 267,
    area: P2_15,
    color: pink,
  },
  {
    x: 336,
    y: 283,
    title: "P2-16",
    badge: "",
    ax: 237,
    ay: 161,
    area: P2_16,
    color: pink,
  },
  {
    x: 492,
    y: 273,
    title: "P2-17",
    badge: "",
    ax: 377,
    ay: 161,
    area: P2_17,
    color: pink,
  },
  {
    x: 577,
    y: 366,
    title: "P2-18",
    badge: "",
    ax: 527,
    ay: 307,
    area: P2_18,
    color: pink,
  },
  {
    x: 598,
    y: 460,
    title: "P2-19",
    badge: "",
    ax: 527,
    ay: 386,
    area: P2_19,
    color: pink,
  },
  {
    x: 638,
    y: 532,
    title: "P2-20",
    badge: "",
    ax: 557,
    ay: 451,
    area: P2_20,
    color: pink,
  },
  {
    x: 662,
    y: 600,
    title: "P2-21",
    badge: "",
    ax: 586,
    ay: 518,
    area: P2_21,
    color: pink,
  },
  {
    x: 644,
    y: 685,
    title: "P2-22",
    badge: "",
    ax: 574,
    ay: 591,
    area: P2_22,
    color: pink,
  },
  {
    x: 631,
    y: 772,
    title: "P2-23",
    badge: "",
    ax: 574,
    ay: 712,
    area: P2_23,
    color: pink,
  },
  {
    x: 801,
    y: 520,
    title: "P2-24",
    badge: "",
    ax: 772,
    ay: 409,
    area: P2_24,
    color: pink,
  },
  {
    x: 846,
    y: 455,
    title: "P2-25",
    badge: "",
    ax: 797,
    ay: 345,
    area: P2_25,
    color: pink,
  },
  {
    x: 906,
    y: 388,
    title: "P2-26",
    badge: "",
    ax: 847,
    ay: 278,
    area: P2_26,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 444,
    ay: 651,
    area: P2_27,
    color: pink,
  },
  {
    x: 459,
    y: 643,
    title: "P2-28",
    badge: "",
    ax: 420,
    ay: 573,
    area: P2_28,
    color: pink,
  },
  {
    x: 433,
    y: 566,
    title: "P2-29",
    badge: "",
    ax: 386,
    ay: 477,
    area: P2_29,
    color: pink,
  },
  {
    x: 412,
    y: 472,
    title: "P2-30",
    badge: "",
    ax: 359,
    ay: 410,
    area: P2_30,
    color: pink,
  },
];

const phase3AreaSet = [
  {
    x: 578,
    y: 788,
    title: "",
    badge: "",
    ax: 717,
    ay: 765,
    area: P3_1,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 672,
    ay: 755,
    area: P3_2,
    color: pink,
  },
  {
    x: 643,
    y: 776,
    title: "P3-3",
    badge: "",
    ax: 626,
    ay: 746,
    area: P3_3,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 581,
    ay: 735,
    area: P3_4,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 536,
    ay: 725,
    area: P3_5,
    color: pink,
  },
  {
    x: 504,
    y: 743,
    title: "P3-6",
    badge: "",
    ax: 493,
    ay: 716,
    area: P3_6,
    color: pink,
  },
  {
    x: 439,
    y: 728,
    title: "P3-7",
    badge: "",
    ax: 427,
    ay: 702,
    area: P3_7,
    color: pink,
  },
  {
    x: 517,
    y: 680,
    title: "P3-8",
    badge: "",
    ax: 504,
    ay: 652,
    area: P3_8,
    color: pink,
  },
  {
    x: 563,
    y: 688,
    title: "P3-9",
    badge: "",
    ax: 551,
    ay: 660,
    area: P3_9,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 595,
    ay: 671,
    area: P3_10,
    color: pink,
  },
  {
    x: 661,
    y: 713,
    title: "P3-11",
    badge: "",
    ax: 641,
    ay: 680,
    area: P3_11,
    color: pink,
  },
  {
    x: 731,
    y: 712,
    title: "P3-12",
    badge: "",
    ax: 701,
    ay: 671,
    area: P3_12,
    color: pink,
  },
  {
    x: 744,
    y: 654,
    title: "P3-13",
    badge: "",
    ax: 720,
    ay: 627,
    area: P3_13,
    color: pink,
  },
  {
    x: 757,
    y: 606,
    title: "P3-14",
    badge: "",
    ax: 736,
    ay: 578,
    area: P3_14,
    color: pink,
  },
  {
    x: 771,
    y: 553,
    title: "P3-15",
    badge: "",
    ax: 747,
    ay: 532,
    area: P3_15,
    color: pink,
  },
  {
    x: 771,
    y: 497,
    title: "P3-16",
    badge: "",
    ax: 741,
    ay: 463,
    area: P3_16,
    color: pink,
  },
  {
    x: 758,
    y: 448,
    title: "P3-17",
    badge: "",
    ax: 729,
    ay: 421,
    area: P3_17,
    color: pink,
  },
  {
    x: 747,
    y: 399,
    title: "P3-18",
    badge: "",
    ax: 711,
    ay: 367,
    area: P3_18,
    color: pink,
  },

  {
    x: 542,
    y: 585,
    title: "P3-25",
    badge: "",
    ax: 530,
    ay: 543,
    area: P3_25,
    color: pink,
  },
  {
    x: 587,
    y: 593,
    title: "P3-26",
    badge: "",
    ax: 575,
    ay: 552,
    area: P3_26,
    color: pink,
  },
  {
    x: 649,
    y: 632,
    title: "P3-27",
    badge: "",
    ax: 620,
    ay: 609,
    area: P3_27,
    color: pink,
  },
  {
    x: 659,
    y: 586,
    title: "P3-28",
    badge: "",
    ax: 629,
    ay: 563,
    area: P3_28,
    color: pink,
  },
  {
    x: 671,
    y: 537,
    title: "P3-29",
    badge: "",
    ax: 641,
    ay: 518,
    area: P3_29,
    color: pink,
  },
  {
    x: 660,
    y: 488,
    title: "P3-30",
    badge: "",
    ax: 631,
    ay: 458,
    area: P3_30,
    color: pink,
  },
  {
    x: 646,
    y: 440,
    title: "P3-31",
    badge: "",
    ax: 622,
    ay: 404,
    area: P3_31,
    color: pink,
  },
  {
    x: 623,
    y: 391,
    title: "P3-32",
    badge: "",
    ax: 593,
    ay: 358,
    area: P3_32,
    color: pink,
  },

  {
    x: 495,
    y: 406,
    title: "P3-35",
    badge: "",
    ax: 457,
    ay: 371,
    area: P3_35,
    color: pink,
  },
  {
    x: 417,
    y: 314,
    title: "P3-36",
    badge: "",
    ax: 371,
    ay: 234,
    area: P3_36,
    color: pink,
  },
  {
    x: 449,
    y: 272,
    title: "P3-37",
    badge: "",
    ax: 398,
    ay: 226,
    area: P3_37,
    color: pink,
  },
  {
    x: 566,
    y: 345,
    title: "P3-38",
    badge: "",
    ax: 531,
    ay: 305,
    area: P3_38,
    color: pink,
  },
  {
    x: 540,
    y: 305,
    title: "P3-39",
    badge: "",
    ax: 518,
    ay: 266,
    area: P3_39,
    color: pink,
  },
  {
    x: 519,
    y: 258,
    title: "P3-40",
    badge: "",
    ax: 489,
    ay: 224,
    area: P3_40,
    color: pink,
  },
  {
    x: 409,
    y: 258,
    title: "",
    badge: "",
    ax: 460,
    ay: 181,
    area: P3_41,
    color: pink,
  },
  {
    x: 464,
    y: 160,
    title: "P3-42",
    badge: "",
    ax: 446,
    ay: 126,
    area: P3_42,
    color: pink,
  },
  {
    x: 405,
    y: 155,
    title: "P3-43",
    badge: "",
    ax: 398,
    ay: 116,
    area: P3_43,
    color: pink,
  },
  {
    x: 355,
    y: 154,
    title: "P3-44",
    badge: "",
    ax: 348,
    ay: 112,
    area: P3_44,
    color: pink,
  },
  // {
  //   x: 69,
  //   y: 165,
  //   title: "P3-45",
  //   badge: "",
  //   ax: 37,
  //   ay: 109,
  //   area: P3_45,
  //   color: pink,
  // },
  // {
  //   x: 132,
  //   y: 214,
  //   title: "P3-46",
  //   badge: "",
  //   ax: 84,
  //   ay: 137,
  //   area: P3_46,
  //   color: pink,
  // },
  {
    x: 176,
    y: 563,
    title: "P3-48",
    badge: "",
    ax: 155,
    ay: 479,
    area: P3_48,
    color: pink,
  },
  {
    x: 239,
    y: 500,
    title: "P3-49",
    badge: "",
    ax: 205,
    ay: 451,
    area: P3_49,
    color: pink,
  },
  {
    x: 281,
    y: 539,
    title: "P3-50",
    badge: "",
    ax: 219,
    ay: 501,
    area: P3_50,
    color: pink,
  },
  {
    x: 270,
    y: 603,
    title: "P3-51",
    badge: "",
    ax: 239,
    ay: 538,
    area: P3_51,
    color: pink,
  },
  {
    x: 311,
    y: 650,
    title: "P3-52",
    badge: "",
    ax: 284,
    ay: 606,
    area: P3_52,
    color: pink,
  },
  {
    x: 372,
    y: 703,
    title: "P3-53",
    badge: "",
    ax: 338,
    ay: 654,
    area: P3_53,
    color: pink,
  },
  {
    x: 267,
    y: 723,
    title: "P3-54",
    badge: "",
    ax: 235,
    ay: 669,
    area: P3_54,
    color: pink,
  },
  // {
  //   x: 65,
  //   y: 539,
  //   title: "P3-68",
  //   badge: "",
  //   ax: 18,
  //   ay: 472,
  //   area: P3_68,
  //   color: pink,
  // },
  // {
  //   x: 134,
  //   y: 567,
  //   title: "P3-69",
  //   badge: "",
  //   ax: 80,
  //   ay: 494,
  //   area: P3_69,
  //   color: pink,
  // },
];

// here x and y for black background and ax and ay for area image
const phase4AreaSet = [
  {
    x: 1052,
    y: 1440,
    title: "C01",
    badge: "",
    ax: 1039,
    ay: 1457,
    rotate: 39,
    area: C01,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1039,
    y: 1402,
    title: "C02",
    badge: "",
    ax: 1021,
    ay: 1419,
    area: C02,
    color: pink,
    type: "chalet",
  },
  {
    x: 998,
    y: 1388,
    title: "C03",
    badge: "",
    ax: 1018,
    ay: 1353,
    area: C03,
    color: pink,
    type: "chalet",
  },
  {
    x: 1100,
    y: 1383,
    title: "C04",
    badge: "",
    ax: 1100,
    ay: 1413,
    area: C04,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1167,
    y: 1496,
    title: "C05",
    badge: "",
    ax: 1169,
    ay: 1467,
    area: C05,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 942,
    y: 1316,
    title: "C06",
    badge: "",
    ax: 922,
    ay: 1328,
    area: C06,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 931,
    y: 1280,
    title: "C07",
    badge: "",
    ax: 925,
    ay: 1254,
    area: C07,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 875,
    y: 1378,
    title: "C08",
    badge: "",
    ax: 876,
    ay: 1342,
    area: C08,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 862,
    y: 1238,
    title: "C09",
    badge: "",
    ax: 846,
    ay: 1258,
    area: C09,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 795,
    y: 1274,
    title: "C10",
    badge: "",
    ax: 779,
    ay: 1296,
    area: C10,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 757,
    y: 1373,
    title: "C11",
    badge: "",
    ax: 740,
    ay: 1397,
    area: C11,
    color: pink,
    type: "chalet",
  },
  {
    x: 818,
    y: 1392,
    title: "C12",
    badge: "",
    ax: 812,
    ay: 1366,
    area: C12,
    color: pink,
    type: "chalet",
  },
  {
    x: 725,
    y: 1289,
    title: "C13",
    badge: "",
    ax: 719,
    ay: 1312,
    area: C13,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 673,
    y: 1345,
    title: "C14",
    badge: "",
    ax: 667,
    ay: 1370,
    area: C14,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 622,
    y: 1338,
    title: "C15",
    badge: "",
    ax: 613,
    ay: 1357,
    area: C15,
    color: pink,
    type: "chalet",
  },
  {
    x: 1086,
    y: 1504,
    title: "C16",
    badge: "",
    ax: 1123,
    ay: 1485,
    area: C16,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1160,
    y: 1566,
    title: "C17",
    badge: "",
    ax: 1127,
    ay: 1570,
    area: C17,
    color: pink,
    type: "chalet",
  },
  {
    x: 1159,
    y: 1606,
    title: "C18",
    badge: "",
    ax: 1132,
    ay: 1612,
    area: C18,
    color: pink,
    type: "chalet",
  },
  {
    x: 1195,
    y: 1634,
    title: "C19",
    badge: "",
    ax: 1168,
    ay: 1643,
    area: C19,
    color: pink,
    type: "chalet",
  },
  {
    x: 1193,
    y: 1687,
    title: "C20",
    badge: "",
    ax: 1227,
    ay: 1658,
    area: C20,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1284,
    y: 1699,
    title: "C21",
    badge: "",
    ax: 1258,
    ay: 1704,
    area: C21,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1304,
    y: 1729,
    title: "C22",
    badge: "",
    ax: 1270,
    ay: 1744,
    area: C22,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  // {
  //   x: 1150,
  //   y: 1374,
  //   title: "C23",
  //   badge: "",
  //   ax: 1123,
  //   ay: 1371,
  //   area: C23,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 1352,
    y: 1846,
    title: "C24",
    badge: "",
    ax: 1326,
    ay: 1858,
    area: C24,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1393,
    y: 1888,
    title: "C25",
    badge: "",
    ax: 1367,
    ay: 1896,
    area: C25,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1409,
    y: 1942,
    title: "C26",
    badge: "",
    ax: 1382,
    ay: 1946,
    area: C26,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1427,
    y: 2006,
    title: "C27",
    badge: "",
    ax: 1398,
    ay: 2001,
    area: C27,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1410,
    y: 2061,
    title: "C28",
    badge: "",
    ax: 1385,
    ay: 2052,
    area: C28,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  // {
  //   x: 1252,
  //   y: 1668,
  //   title: "C29",
  //   badge: "",
  //   ax: 1227,
  //   ay: 1656,
  //   area: C29,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 1344,
    y: 2090,
    title: "C30",
    badge: "",
    ax: 1361,
    ay: 2108,
    area: C30,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1317,
    y: 2140,
    title: "C31",
    badge: "",
    ax: 1333,
    ay: 2160,
    area: C31,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1270,
    y: 2159,
    title: "C32",
    badge: "",
    ax: 1284,
    ay: 2184,
    area: C32,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1148,
    y: 1786,
    title: "C33",
    badge: "",
    ax: 1167,
    ay: 1812,
    area: C33,
    color: pink,
    type: "chalet",
  },
  {
    x: 1241,
    y: 1945,
    title: "C34",
    badge: "",
    ax: 1240,
    ay: 1917,
    area: C34,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1107,
    y: 1808,
    title: "C35",
    badge: "",
    ax: 1116,
    ay: 1830,
    area: C35,
    color: pink,
    type: "chalet",
  },
  {
    x: 1173,
    y: 1911,
    title: "C36",
    badge: "",
    ax: 1158,
    ay: 1886,
    area: C36,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1173,
    y: 1985,
    title: "C37",
    badge: "",
    ax: 1162,
    ay: 1951,
    area: C37,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  // {
  //   x: 1090,
  //   y: 1603,
  //   title: "C38",
  //   badge: "",
  //   ax: 1070,
  //   ay: 1580,
  //   area: C38,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 1123,
    y: 1990,
    title: "C39",
    badge: "",
    ax: 1115,
    ay: 1959,
    area: C39,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  // {
  //   x: 1004,
  //   y: 1606,
  //   title: "C40",
  //   badge: "",
  //   ax: 1012,
  //   ay: 1581,
  //   area: C40,
  //   color: pink,
  //   type: "chalet",
  // },
  // {
  //   x: 975,
  //   y: 1508,
  //   title: "C41",
  //   badge: "",
  //   ax: 966,
  //   ay: 1476,
  //   area: C41,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 996,
    y: 1884,
    title: "C42",
    badge: "",
    ax: 1030,
    ay: 1859,
    area: C42,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1029,
    y: 1922,
    title: "C43",
    badge: "",
    ax: 1075,
    ay: 1895,
    area: C43,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 1029,
    y: 1996,
    title: "C44",
    badge: "",
    ax: 1048,
    ay: 1962,
    area: C44,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 890,
    y: 2015,
    title: "C45",
    badge: "",
    ax: 908,
    ay: 2038,
    area: C45,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 862,
    y: 2101,
    title: "C46",
    badge: "",
    ax: 819,
    ay: 2090,
    area: C46,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 931,
    y: 1993,
    title: "C47",
    badge: "",
    ax: 950,
    ay: 2013,
    area: C47,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 994,
    y: 2021,
    title: "C48",
    badge: "",
    ax: 987,
    ay: 1988,
    area: C48,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  // {
  //   x: 834,
  //   y: 1536,
  //   title: "C49",
  //   badge: "",
  //   ax: 834,
  //   ay: 1505,
  //   area: C49,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 936,
    y: 1914,
    title: "C50",
    badge: "",
    ax: 911,
    ay: 1927,
    area: C50,
    color: pink,
    type: "chalet",
  },
  {
    x: 824,
    y: 1980,
    title: "C51",
    badge: "",
    ax: 843,
    ay: 1951,
    area: C51,
    color: pink,
    type: "chalet",
  },
  {
    x: 941,
    y: 2100,
    title: "C52",
    badge: "",
    ax: 964,
    ay: 2126,
    area: C52,
    color: pink,
    type: "chalet",
  },
  {
    x: 1003,
    y: 2089,
    title: "C53",
    badge: "",
    ax: 1020,
    ay: 2115,
    area: C53,
    color: pink,
    type: "chalet",
  },
  {
    x: 1068,
    y: 2090,
    title: "C54",
    badge: "",
    ax: 1083,
    ay: 2120,
    area: C54,
    color: pink,
    type: "chalet",
  },
  {
    x: 1125,
    y: 2074,
    title: "C55",
    badge: "",
    ax: 1138,
    ay: 2101,
    area: C55,
    color: pink,
    type: "chalet",
  },
  {
    x: 1179,
    y: 2058,
    title: "C56",
    badge: "",
    ax: 1206,
    ay: 2078,
    area: C56,
    color: pink,
    type: "chalet",
  },
  {
    x: 1238,
    y: 2027,
    title: "C57",
    badge: "",
    ax: 1259,
    ay: 2045,
    area: C57,
    color: pink,
    type: "chalet",
  },
  {
    x: 777,
    y: 1959,
    title: "C58",
    badge: "",
    ax: 789,
    ay: 1920,
    area: C58,
    color: pink,
    type: "chalet",
  },
  // {
  //   x: 662,
  //   y: 1598,
  //   title: "C59",
  //   badge: "",
  //   ax: 661,
  //   ay: 1571,
  //   area: C59,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 740,
    y: 1890,
    title: "C60",
    badge: "",
    ax: 731,
    ay: 1852,
    area: C60,
    color: pink,
    type: "chalet",
  },
  {
    x: 679,
    y: 1954,
    title: "C61",
    badge: "",
    ax: 717,
    ay: 1947,
    area: C61,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 601,
    y: 1936,
    title: "C62",
    badge: "",
    ax: 600,
    ay: 1898,
    area: C62,
    color: pink,
    type: "chalet",
  },
  {
    x: 667,
    y: 1887,
    title: "C63",
    badge: "",
    ax: 678,
    ay: 1907,
    area: C63,
    color: pink,
    type: "chalet",
    isPin: true,
  },
  {
    x: 710,
    y: 1813,
    title: "C64",
    badge: "",
    ax: 676,
    ay: 1828,
    area: C64,
    color: pink,
    type: "chalet",
  },
  {
    x: 406,
    y: 1345,
    title: "C65",
    badge: "",
    ax: 404,
    ay: 1315,
    rotate: 39,
    area: C65,
    color: pink,
    type: "chalet",
  },
  {
    x: 429,
    y: 1243,
    title: "C66",
    badge: "",
    ax: 426,
    ay: 1264,
    area: C66,
    color: pink,
    type: "chalet",
  },
  {
    x: 423,
    y: 1164,
    title: "C67",
    badge: "",
    ax: 424,
    ay: 1195,
    area: C67,
    color: pink,
    type: "chalet",
  },
  {
    x: 569,
    y: 1230,
    title: "C68",
    badge: "",
    ax: 534,
    ay: 1230,
    area: C68,
    color: pink,
    type: "chalet",
  },
  {
    x: 551,
    y: 1189,
    title: "C69",
    badge: "",
    ax: 512,
    ay: 1196,
    area: C69,
    color: pink,
    type: "chalet",
  },
  {
    x: 523,
    y: 1119,
    title: "C70",
    badge: "",
    ax: 540,
    ay: 1146,
    area: C70,
    color: pink,
    type: "chalet",
  },
  {
    x: 436,
    y: 1107,
    title: "C71",
    badge: "",
    ax: 441,
    ay: 1073,
    area: C71,
    color: pink,
    type: "chalet",
  },
  {
    x: 442,
    y: 1007,
    title: "C72",
    badge: "",
    ax: 459,
    ay: 1026,
    area: C72,
    color: pink,
    type: "chalet",
  },
  {
    x: 555,
    y: 1003,
    title: "C73",
    badge: "",
    ax: 523,
    ay: 1004,
    area: C73,
    color: pink,
    type: "chalet",
  },
  {
    x: 914,
    y: 1475,
    title: "M01",
    badge: "",
    ax: 925,
    ay: 1494,
    area: M01,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 816,
    y: 1535,
    title: "M02",
    badge: "",
    ax: 854,
    ay: 1517,
    area: M02,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 759,
    y: 1474,
    title: "M03",
    badge: "",
    ax: 736,
    ay: 1475,
    area: M03,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 443,
  //   y: 1056,
  //   title: "M04",
  //   badge: "",
  //   ax: 474,
  //   ay: 1039,
  //   area: M04,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 653,
    y: 1496,
    title: "M05",
    badge: "",
    ax: 647,
    ay: 1472,
    area: M05,
    color: pink,
    type: "minichalet",
  },
  {
    x: 546,
    y: 1536,
    title: "M06",
    badge: "",
    ax: 584,
    ay: 1519,
    area: M06,
    color: pink,
    type: "minichalet",
  },
  {
    x: 454,
    y: 1379,
    title: "M07",
    badge: "",
    ax: 455,
    ay: 1406,
    area: M07,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 317,
  //   y: 1035,
  //   title: "M08",
  //   badge: "",
  //   ax: 292,
  //   ay: 1032,
  //   area: M08,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 375,
    y: 1488,
    title: "M09",
    badge: "",
    ax: 379,
    ay: 1509,
    area: M09,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 329,
  //   y: 1093,
  //   title: "M10",
  //   badge: "",
  //   ax: 362,
  //   ay: 1090,
  //   area: M10,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 546,
    y: 1586,
    title: "M11",
    badge: "",
    ax: 579,
    ay: 1594,
    area: M11,
    color: pink,
    type: "minichalet",
  },
  {
    x: 701,
    y: 1529,
    title: "M12",
    badge: "",
    ax: 688,
    ay: 1551,
    area: M12,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 579,
  //   y: 1168,
  //   title: "M13",
  //   badge: "",
  //   ax: 559,
  //   ay: 1164,
  //   area: M13,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 637,
    y: 1623,
    title: "M14",
    badge: "",
    ax: 671,
    ay: 1611,
    area: M14,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 743,
  //   y: 1136,
  //   title: "M15",
  //   badge: "",
  //   ax: 770,
  //   ay: 1121,
  //   area: M15,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 754,
  //   y: 1172,
  //   title: "M16",
  //   badge: "",
  //   ax: 782,
  //   ay: 1172,
  //   area: M16,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 899,
    y: 1559,
    title: "M17",
    badge: "",
    ax: 948,
    ay: 1547,
    area: M17,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 914,
    y: 1642,
    title: "M18",
    badge: "",
    ax: 921,
    ay: 1622,
    area: M18,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 987,
    y: 1619,
    title: "M19",
    badge: "",
    ax: 984,
    ay: 1598,
    area: M19,
    color: pink,
    type: "minichalet",
  },
  {
    x: 813,
    y: 1681,
    title: "M20",
    badge: "",
    ax: 842,
    ay: 1658,
    area: M20,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 785,
    y: 1594,
    title: "M21",
    badge: "",
    ax: 826,
    ay: 1601,
    area: M21,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 790,
    y: 1755,
    title: "M22",
    badge: "",
    ax: 800,
    ay: 1732,
    area: M22,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 813,
    y: 1831,
    title: "M23",
    badge: "",
    ax: 842,
    ay: 1856,
    area: M23,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1058,
    y: 1661,
    title: "M24",
    badge: "",
    ax: 1083,
    ay: 1679,
    area: M24,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1018,
    y: 1717,
    title: "M25",
    badge: "",
    ax: 1028,
    ay: 1740,
    area: M25,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1068,
    y: 1748,
    title: "M26",
    badge: "",
    ax: 1097,
    ay: 1726,
    area: M26,
    color: pink,
    type: "minichalet",
  },
  {
    x: 973,
    y: 1806,
    title: "M27",
    badge: "",
    ax: 1002,
    ay: 1789,
    area: M27,
    color: pink,
    type: "minichalet",
  },
  {
    x: 655,
    y: 1728,
    title: "M28",
    badge: "",
    ax: 686,
    ay: 1744,
    area: M28,
    color: pink,
    type: "minichalet",
  },
  {
    x: 728,
    y: 1630,
    title: "M29",
    badge: "",
    ax: 766,
    ay: 1629,
    area: M29,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 721,
    y: 1712,
    title: "M30",
    badge: "",
    ax: 746,
    ay: 1688,
    area: M30,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 604,
    y: 1777,
    title: "M31",
    badge: "",
    ax: 639,
    ay: 1777,
    area: M31,
    color: pink,
    type: "minichalet",
  },
  {
    x: 536,
    y: 1766,
    title: "M32",
    badge: "",
    ax: 562,
    ay: 1794,
    area: M32,
    color: pink,
    type: "minichalet",
  },
  {
    x: 571,
    y: 1705,
    title: "M33",
    badge: "",
    ax: 550,
    ay: 1714,
    area: M33,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 469,
  //   y: 1334,
  //   title: "M34",
  //   badge: "",
  //   ax: 450,
  //   ay: 1318,
  //   area: M34,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 645,
    y: 1693,
    title: "M35",
    badge: "",
    ax: 644,
    ay: 1670,
    area: M35,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 417,
  //   y: 1203,
  //   title: "M36",
  //   badge: "",
  //   ax: 452,
  //   ay: 1198,
  //   area: M36,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 438,
  //   y: 1235,
  //   title: "M37",
  //   badge: "",
  //   ax: 472,
  //   ay: 1230,
  //   area: M37,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 530,
    y: 1666,
    title: "M38",
    badge: "",
    ax: 576,
    ay: 1661,
    area: M38,
    color: pink,
    type: "minichalet",
  },
  {
    x: 472,
    y: 1513,
    title: "M39",
    badge: "",
    ax: 473,
    ay: 1537,
    area: M39,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 301,
  //   y: 1151,
  //   title: "M40",
  //   badge: "",
  //   ax: 333,
  //   ay: 1142,
  //   area: M40,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 417,
    y: 1586,
    title: "M41",
    badge: "",
    ax: 461,
    ay: 1592,
    area: M41,
    color: pink,
    type: "minichalet",
  },
  {
    x: 383,
    y: 1633,
    title: "M42",
    badge: "",
    ax: 434,
    ay: 1629,
    area: M42,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 356,
  //   y: 1230,
  //   title: "M43",
  //   badge: "",
  //   ax: 342,
  //   ay: 1240,
  //   area: M43,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 438,
    y: 1715,
    title: "M44",
    badge: "",
    ax: 452,
    ay: 1691,
    area: M44,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 348,
    y: 1675,
    title: "M45",
    badge: "",
    ax: 364,
    ay: 1697,
    area: M45,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 373,
    y: 1743,
    title: "M46",
    badge: "",
    ax: 413,
    ay: 1747,
    area: M46,
    color: pink,
    type: "minichalet",
  },
  {
    x: 278,
    y: 2363,
    title: "M47",
    badge: "",
    ax: 305,
    ay: 2345,
    area: M47,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 139,
  //   y: 1949,
  //   title: "M48",
  //   badge: "",
  //   ax: 159,
  //   ay: 1957,
  //   area: M48,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 216,
    y: 2401,
    title: "M49",
    badge: "",
    ax: 235,
    ay: 2373,
    area: M49,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 419,
    y: 2329,
    title: "M50",
    badge: "",
    ax: 396,
    ay: 2346,
    area: M50,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 317,
    y: 2440,
    title: "M51",
    badge: "",
    ax: 316,
    ay: 2415,
    area: M51,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 389,
    y: 2401,
    title: "M52",
    badge: "",
    ax: 405,
    ay: 2427,
    area: M52,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 308,
  //   y: 1979,
  //   title: "M53",
  //   badge: "",
  //   ax: 316,
  //   ay: 1992,
  //   area: M53,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 456,
    y: 2360,
    title: "M54",
    badge: "",
    ax: 461,
    ay: 2384,
    area: M54,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 368,
    y: 2504,
    title: "M55",
    badge: "",
    ax: 347,
    ay: 2488,
    area: M55,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 315,
    y: 2546,
    title: "M56",
    badge: "",
    ax: 328,
    ay: 2522,
    area: M56,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 273,
    y: 2481,
    title: "M57",
    badge: "",
    ax: 281,
    ay: 2450,
    area: M57,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 157,
  //   y: 2068,
  //   title: "M58",
  //   badge: "",
  //   ax: 137,
  //   ay: 2068,
  //   area: M58,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 251,
  //   y: 2130,
  //   title: "M59",
  //   badge: "",
  //   ax: 261,
  //   ay: 2108,
  //   area: M59,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 276,
  //   y: 2170,
  //   title: "M60",
  //   badge: "",
  //   ax: 275,
  //   ay: 2146,
  //   area: M60,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 462,
    y: 2442,
    title: "M61",
    badge: "",
    ax: 477,
    ay: 2462,
    area: M61,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 429,
    y: 2496,
    title: "M62",
    badge: "",
    ax: 458,
    ay: 2513,
    area: M62,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 206,
    y: 2519,
    title: "M63",
    badge: "",
    ax: 239,
    ay: 2543,
    area: M63,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 135,
  //   y: 2167,
  //   title: "M64",
  //   badge: "",
  //   ax: 143,
  //   ay: 2144,
  //   area: M64,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 192,
  //   y: 2185,
  //   title: "M65",
  //   badge: "",
  //   ax: 200,
  //   ay: 2162,
  //   area: M65,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 394,
    y: 2681,
    title: "M66",
    badge: "",
    ax: 392,
    ay: 2661,
    area: M66,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 389,
    y: 2570,
    title: "M67",
    badge: "",
    ax: 397,
    ay: 2591,
    area: M67,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 248,
  //   y: 2304,
  //   title: "M68",
  //   badge: "",
  //   ax: 277,
  //   ay: 2284,
  //   area: M68,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 297,
  //   y: 2341,
  //   title: "M69",
  //   badge: "",
  //   ax: 330,
  //   ay: 2333,
  //   area: M69,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 417,
    y: 2736,
    title: "M70",
    badge: "",
    ax: 446,
    ay: 2770,
    area: M70,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 461,
    y: 2816,
    title: "M71",
    badge: "",
    ax: 501,
    ay: 2842,
    area: M71,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 452,
    y: 2648,
    title: "M72",
    badge: "",
    ax: 484,
    ay: 2681,
    area: M72,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  {
    x: 491,
    y: 2727,
    title: "M73",
    badge: "",
    ax: 513,
    ay: 2761,
    area: M73,
    color: pink,
    type: "minichalet",
    isPin: true,
  },
  // {
  //   x: 333,
  //   y: 1920,
  //   title: "M77",
  //   badge: "",
  //   ax: 313,
  //   ay: 1911,
  //   area: M77,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 440,
  //   y: 2340,
  //   title: "D01",
  //   badge: "",
  //   ax: 475,
  //   ay: 2340,
  //   area: D01,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 426,
  //   y: 2372,
  //   title: "D02",
  //   badge: "",
  //   ax: 470,
  //   ay: 2372,
  //   area: D02,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 423,
  //   y: 2403,
  //   title: "D03",
  //   badge: "",
  //   ax: 465,
  //   ay: 2403,
  //   area: D03,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 582,
  //   y: 2404,
  //   title: "D04",
  //   badge: "",
  //   ax: 562,
  //   ay: 2422,
  //   area: D04,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 594,
  //   y: 2443,
  //   title: "D05",
  //   badge: "",
  //   ax: 585,
  //   ay: 2464,
  //   area: D05,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 631,
  //   y: 2428,
  //   title: "D06",
  //   badge: "",
  //   ax: 633,
  //   ay: 2450,
  //   area: D06,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 691,
  //   y: 2433,
  //   title: "D07",
  //   badge: "",
  //   ax: 669,
  //   ay: 2452,
  //   area: D07,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 741,
  //   y: 2458,
  //   title: "D08",
  //   badge: "",
  //   ax: 704,
  //   ay: 2469,
  //   area: D08,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 756,
  //   y: 2492,
  //   title: "D09",
  //   badge: "",
  //   ax: 726,
  //   ay: 2502,
  //   area: D09,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 404,
  //   y: 2480,
  //   title: "D10",
  //   badge: "",
  //   ax: 419,
  //   ay: 2495,
  //   area: D10,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 433,
  //   y: 2442,
  //   title: "D11",
  //   badge: "",
  //   ax: 440,
  //   ay: 2460,
  //   area: D11,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 477,
  //   y: 2442,
  //   title: "D12",
  //   badge: "",
  //   ax: 480,
  //   ay: 2464,
  //   area: D12,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 402,
  //   y: 2656,
  //   title: "D13",
  //   badge: "",
  //   ax: 438,
  //   ay: 2659,
  //   area: D13,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 418,
  //   y: 2590,
  //   title: "D14",
  //   badge: "",
  //   ax: 435,
  //   ay: 2614,
  //   area: D14,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 447,
  //   y: 2546,
  //   title: "D15",
  //   badge: "",
  //   ax: 455,
  //   ay: 2564,
  //   area: D15,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 497,
  //   y: 2644,
  //   title: "D16",
  //   badge: "",
  //   ax: 523,
  //   ay: 2653,
  //   area: D16,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 529,
  //   y: 2627,
  //   title: "D17",
  //   badge: "",
  //   ax: 560,
  //   ay: 2633,
  //   area: D17,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 530,
  //   y: 2597,
  //   title: "D18",
  //   badge: "",
  //   ax: 565,
  //   ay: 2589,
  //   area: D18,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 774,
  //   y: 2589,
  //   title: "D19",
  //   badge: "",
  //   ax: 739,
  //   ay: 2579,
  //   area: D19,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 761,
  //   y: 2632,
  //   title: "D20",
  //   badge: "",
  //   ax: 727,
  //   ay: 2610,
  //   area: D20,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 716,
  //   y: 2652,
  //   title: "D21",
  //   badge: "",
  //   ax: 687,
  //   ay: 2626,
  //   area: D21,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 643,
  //   y: 2711,
  //   title: "D22",
  //   badge: "",
  //   ax: 619,
  //   ay: 2675,
  //   area: D22,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 616,
  //   y: 2736,
  //   title: "D23",
  //   badge: "",
  //   ax: 592,
  //   ay: 2699,
  //   area: D23,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 581,
  //   y: 2759,
  //   title: "D24",
  //   badge: "",
  //   ax: 565,
  //   ay: 2719,
  //   area: D24,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 362,
  //   y: 2783,
  //   title: "D25",
  //   badge: "",
  //   ax: 383,
  //   ay: 2748,
  //   area: D25,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 414,
  //   y: 2788,
  //   title: "D26",
  //   badge: "",
  //   ax: 419,
  //   ay: 2752,
  //   area: D26,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 461,
  //   y: 2782,
  //   title: "D27",
  //   badge: "",
  //   ax: 458,
  //   ay: 2748,
  //   area: D27,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 794,
  //   y: 2681,
  //   title: "D28",
  //   badge: "",
  //   ax: 796,
  //   ay: 2651,
  //   area: D28,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 916,
  //   y: 2643,
  //   title: "D29",
  //   badge: "",
  //   ax: 881,
  //   ay: 2634,
  //   area: D29,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 912,
  //   y: 2676,
  //   title: "D30",
  //   badge: "",
  //   ax: 877,
  //   ay: 2666,
  //   area: D30,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 897,
  //   y: 2777,
  //   title: "D31",
  //   badge: "",
  //   ax: 868,
  //   ay: 2742,
  //   area: D31,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 861,
  //   y: 2802,
  //   title: "D32",
  //   badge: "",
  //   ax: 840,
  //   ay: 2763,
  //   area: D32,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 814,
  //   y: 2825,
  //   title: "D33",
  //   badge: "",
  //   ax: 816,
  //   ay: 2792,
  //   area: D33,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 752,
  //   y: 2729,
  //   title: "D34",
  //   badge: "",
  //   ax: 720,
  //   ay: 2721,
  //   area: D34,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 741,
  //   y: 2772,
  //   title: "D35",
  //   badge: "",
  //   ax: 704,
  //   ay: 2756,
  //   area: D35,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 699,
  //   y: 2814,
  //   title: "D36",
  //   badge: "",
  //   ax: 674,
  //   ay: 2778,
  //   area: D36,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 648,
  //   y: 2839,
  //   title: "D37",
  //   badge: "",
  //   ax: 629,
  //   ay: 2805,
  //   area: D37,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 616,
  //   y: 2861,
  //   title: "D38",
  //   badge: "",
  //   ax: 598,
  //   ay: 2822,
  //   area: D38,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 580,
  //   y: 2881,
  //   title: "D39",
  //   badge: "",
  //   ax: 568,
  //   ay: 2840,
  //   area: D39,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 369,
  //   y: 2859,
  //   title: "D40",
  //   badge: "",
  //   ax: 387,
  //   ay: 2878,
  //   area: D40,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 403,
  //   y: 2841,
  //   title: "D41",
  //   badge: "",
  //   ax: 417,
  //   ay: 2860,
  //   area: D41,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 436,
  //   y: 2828,
  //   title: "D42",
  //   badge: "",
  //   ax: 449,
  //   ay: 2843,
  //   area: D42,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 371,
  //   y: 3010,
  //   title: "D43",
  //   badge: "",
  //   ax: 365,
  //   ay: 2971,
  //   area: D43,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 416,
  //   y: 3000,
  //   title: "D44",
  //   badge: "",
  //   ax: 401,
  //   ay: 2962,
  //   area: D44,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 449,
  //   y: 2982,
  //   title: "D45",
  //   badge: "",
  //   ax: 432,
  //   ay: 2945,
  //   area: D45,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 484,
  //   y: 2970,
  //   title: "D46",
  //   badge: "",
  //   ax: 462,
  //   ay: 2925,
  //   area: D46,
  //   color: pink,
  //   type: "dome",
  // },
  // // {
  // //   x: 25-3,
  // //   y: 1822,
  // //   title: "D47",
  // //   badge: "",
  // //   ax: 230,
  // //   ay: 1797,
  // //   area: D47,
  // //   color: pink,
  // //   type: "dome",
  // // },
  // {
  //   x: 542,
  //   y: 2939,
  //   title: "D48",
  //   badge: "",
  //   ax: 525,
  //   ay: 2901,
  //   area: D48,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 521,
  //   y: 2958,
  //   title: "D49",
  //   badge: "",
  //   ax: 497,
  //   ay: 2916,
  //   area: D49,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 502,
  //   y: 2695,
  //   title: "D50",
  //   badge: "",
  //   ax: 462,
  //   ay: 2690,
  //   area: D50,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 489,
  //   y: 2534,
  //   title: "D51",
  //   badge: "",
  //   ax: 494,
  //   ay: 2552,
  //   area: D51,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 539,
  //   y: 2523,
  //   title: "D52",
  //   badge: "",
  //   ax: 547,
  //   ay: 2543,
  //   area: D52,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 771,
  //   y: 2554,
  //   title: "D53",
  //   badge: "",
  //   ax: 740,
  //   ay: 2545,
  //   area: D53,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 908,
  //   y: 2710,
  //   title: "D54",
  //   badge: "",
  //   ax: 872,
  //   ay: 2698,
  //   area: D54,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 770,
  //   y: 2814,
  //   title: "D55",
  //   badge: "",
  //   ax: 778,
  //   ay: 2774,
  //   area: D55,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 754,
  //   y: 2693,
  //   title: "D56",
  //   badge: "",
  //   ax: 722,
  //   ay: 2687,
  //   area: D56,
  //   color: pink,
  //   type: "dome",
  // },
  // {
  //   x: 790,
  //   y: 2742,
  //   title: "D58",
  //   badge: "",
  //   ax: 795,
  //   ay: 2710,
  //   area: D58,
  //   color: pink,
  //   type: "dome",
  // },
];

const phase4DomeAreaSet = [
  {
    x: 310,
    y: 1762,
    title: "D01",
    badge: "",
    ax: 340,
    ay: 1758,
    area: D01,
    color: pink,
    type: "dome",
  },
  {
    x: 302,
    y: 1786,
    title: "D02",
    badge: "",
    ax: 336,
    ay: 1782,
    area: D02,
    color: pink,
    type: "dome",
  },
  {
    x: 300,
    y: 1810,
    title: "D03",
    badge: "",
    ax: 332,
    ay: 1807,
    area: D03,
    color: pink,
    type: "dome",
  },
  {
    x: 430,
    y: 1814,
    title: "D04",
    badge: "",
    ax: 425,
    ay: 1829,
    area: D04,
    color: pink,
    type: "dome",
  },
  {
    x: 448,
    y: 1849,
    title: "D05",
    badge: "",
    ax: 438,
    ay: 1861,
    area: D05,
    color: pink,
    type: "dome",
  },
  {
    x: 476,
    y: 1838,
    title: "D06",
    badge: "",
    ax: 476,
    ay: 1865,
    area: D06,
    color: pink,
    type: "dome",
  },
  {
    x: 515,
    y: 1844,
    title: "D07",
    badge: "",
    ax: 502,
    ay: 1855,
    area: D07,
    color: pink,
    type: "dome",
  },
  {
    x: 552,
    y: 1862,
    title: "D08",
    badge: "",
    ax: 529,
    ay: 1866,
    area: D08,
    color: pink,
    type: "dome",
  },
  {
    x: 572,
    y: 1889,
    title: "D09",
    badge: "",
    ax: 546,
    ay: 1889,
    area: D09,
    color: pink,
    type: "dome",
  },
  {
    x: 296,
    y: 1880,
    title: "D10",
    badge: "",
    ax: 317,
    ay: 1890,
    area: D10,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 292,
    y: 1836,
    title: "D11",
    badge: "",
    ax: 325,
    ay: 1837,
    area: D11,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 355,
    y: 1848,
    title: "D12",
    badge: "",
    ax: 361,
    ay: 1871,
    area: D12,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 341,
    y: 2008,
    title: "D13",
    badge: "",
    ax: 316,
    ay: 2006,
    area: D13,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 340,
    y: 1983,
    title: "D14",
    badge: "",
    ax: 316,
    ay: 1964,
    area: D14,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 352,
    y: 1962,
    title: "D15",
    badge: "",
    ax: 337,
    ay: 1930,
    area: D15,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 378,
    y: 2003,
    title: "D16",
    badge: "",
    ax: 395,
    ay: 2007,
    area: D16,
    color: pink,
    type: "dome",
  },
  {
    x: 393,
    y: 1982,
    title: "D17",
    badge: "",
    ax: 424,
    ay: 1987,
    area: D17,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 394,
    y: 1964,
    title: "D18",
    badge: "",
    ax: 423,
    ay: 1952,
    area: D18,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 582,
    y: 1959,
    title: "D19",
    badge: "",
    ax: 556,
    ay: 1947,
    area: D19,
    color: pink,
    type: "dome",
  },
  {
    x: 572,
    y: 1988,
    title: "D20",
    badge: "",
    ax: 545,
    ay: 1971,
    area: D20,
    color: pink,
    type: "dome",
  },
  {
    x: 537,
    y: 2007,
    title: "D21",
    badge: "",
    ax: 514,
    ay: 1983,
    area: D21,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 479,
    y: 2048,
    title: "D22",
    badge: "",
    ax: 465,
    ay: 2021,
    area: D22,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 460,
    y: 2065,
    title: "D23",
    badge: "",
    ax: 443,
    ay: 2037,
    area: D23,
    color: pink,
    type: "dome",
    isPin: true,
  },
  // {
  //   x: 581,
  //   y: 2759,
  //   title: "D24",
  //   badge: "",
  //   ax: 565,
  //   ay: 2719,
  //   area: D24,
  //   color: pink,
  //   type: "dome",
  // },
  {
    x: 265,
    y: 2089,
    title: "D25",
    badge: "",
    ax: 286,
    ay: 2058,
    area: D25,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 305,
    y: 2089,
    title: "D26",
    badge: "",
    ax: 311,
    ay: 2062,
    area: D26,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 347,
    y: 2091,
    title: "D27",
    badge: "",
    ax: 341,
    ay: 2064,
    area: D27,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 591,
    y: 2028,
    title: "D28",
    badge: "",
    ax: 594,
    ay: 2004,
    area: D28,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 691,
    y: 2001,
    title: "D29",
    badge: "",
    ax: 664,
    ay: 1991,
    area: D29,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 687,
    y: 2027,
    title: "D30",
    badge: "",
    ax: 661,
    ay: 2015,
    area: D30,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 675,
    y: 2104,
    title: "D31",
    badge: "",
    ax: 653,
    ay: 2076,
    area: D31,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 649,
    y: 2121,
    title: "D32",
    badge: "",
    ax: 632,
    ay: 2092,
    area: D32,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 620,
    y: 2140,
    title: "D33",
    badge: "",
    ax: 611,
    ay: 2114,
    area: D33,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 555,
    y: 2065,
    title: "D34",
    badge: "",
    ax: 529,
    ay: 2054,
    area: D34,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 547,
    y: 2109,
    title: "D35",
    badge: "",
    ax: 528,
    ay: 2083,
    area: D35,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 529,
    y: 2130,
    title: "D36",
    badge: "",
    ax: 507,
    ay: 2104,
    area: D36,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 493,
    y: 2151,
    title: "D37",
    badge: "",
    ax: 472,
    ay: 2124,
    area: D37,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 468,
    y: 2167,
    title: "D38",
    badge: "",
    ax: 449,
    ay: 2137,
    area: D38,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 441,
    y: 2183,
    title: "D39",
    badge: "",
    ax: 425,
    ay: 2150,
    area: D39,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 267,
    y: 2163,
    title: "D40",
    badge: "",
    ax: 287,
    ay: 2176,
    area: D40,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 293,
    y: 2149,
    title: "D41",
    badge: "",
    ax: 310,
    ay: 2163,
    area: D41,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 318,
    y: 2130,
    title: "D42",
    badge: "",
    ax: 330,
    ay: 2142,
    area: D42,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 279,
    y: 2273,
    title: "D43",
    badge: "",
    ax: 266,
    ay: 2234,
    area: D43,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 314,
    y: 2265,
    title: "D44",
    badge: "",
    ax: 298,
    ay: 2234,
    area: D44,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 340,
    y: 2251,
    title: "D45",
    badge: "",
    ax: 321,
    ay: 2221,
    area: D45,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 366,
    y: 2242,
    title: "D46",
    badge: "",
    ax: 345,
    ay: 2208,
    area: D46,
    color: pink,
    type: "dome",
    isPin: true,
  },
  // {
  //   x: 25-3,
  //   y: 1822,
  //   title: "D47",
  //   badge: "",
  //   ax: 230,
  //   ay: 1797,
  //   area: D47,
  //   color: pink,
  //   type: "dome",
  // },
  {
    x: 410,
    y: 2219,
    title: "D48",
    badge: "",
    ax: 393,
    ay: 2184,
    area: D48,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 394,
    y: 2233,
    title: "D49",
    badge: "",
    ax: 368,
    ay: 2196,
    area: D49,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 365,
    y: 2030,
    title: "D50",
    badge: "",
    ax: 338,
    ay: 2030,
    area: D50,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 374,
    y: 1944,
    title: "D51",
    badge: "",
    ax: 368,
    ay: 1919,
    area: D51,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 397,
    y: 1909,
    title: "D52",
    badge: "",
    ax: 412,
    ay: 1920,
    area: D52,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 579,
    y: 1932,
    title: "D53",
    badge: "",
    ax: 554,
    ay: 1921,
    area: D53,
    color: pink,
    type: "dome",
  },
  {
    x: 684,
    y: 2052,
    title: "D54",
    badge: "",
    ax: 657,
    ay: 2042,
    area: D52,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 576,
    y: 2128,
    title: "D55",
    badge: "",
    ax: 583,
    ay: 2099,
    area: D55,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 559,
    y: 2036,
    title: "D56",
    badge: "",
    ax: 531,
    ay: 2028,
    area: D56,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 434,
    y: 2082,
    title: "D57",
    badge: "",
    ax: 422,
    ay: 2052,
    area: D57,
    color: pink,
    type: "dome",
    isPin: true,
  },
  {
    x: 593,
    y: 2073,
    title: "D58",
    badge: "",
    ax: 601,
    ay: 2049,
    area: D58,
    color: pink,
    type: "dome",
  },
];

const phase4NordAreaSet = [
  {
    x: 534,
    y: 999,
    title: "C74",
    badge: "",
    ax: 565,
    ay: 947,
    area: C74,
    color: pink,
    type: "chalet",
  },
  {
    x: 567,
    y: 1071,
    title: "C75",
    badge: "",
    ax: 596,
    ay: 1013,
    area: C75,
    color: pink,
    type: "chalet",
  },
  {
    x: 644,
    y: 1106,
    title: "C76",
    badge: "",
    ax: 657,
    ay: 1049,
    area: C76,
    color: pink,
    type: "chalet",
  },
  {
    x: 737,
    y: 1081,
    title: "C77",
    badge: "",
    ax: 749,
    ay: 1024,
    area: C77,
    color: pink,
    type: "chalet",
  },
  {
    x: 829,
    y: 1046,
    title: "C78",
    badge: "",
    ax: 821,
    ay: 988,
    area: C78,
    color: pink,
    type: "chalet",
  },
  {
    x: 941,
    y: 997,
    title: "C79",
    badge: "",
    ax: 958,
    ay: 944,
    area: C79,
    color: pink,
    type: "chalet",
  },
  {
    x: 1031,
    y: 1037,
    title: "C80",
    badge: "",
    ax: 1046,
    ay: 981,
    area: C80,
    color: pink,
    type: "chalet",
  },
  {
    x: 1107,
    y: 1096,
    title: "C81",
    badge: "",
    ax: 1121,
    ay: 1038,
    area: C81,
    color: pink,
    type: "chalet",
  },
  {
    x: 1185,
    y: 1031,
    title: "C82",
    badge: "",
    ax: 1178,
    ay: 977,
    area: C82,
    color: pink,
    type: "chalet",
  },
  {
    x: 1264,
    y: 1011,
    title: "C83",
    badge: "",
    ax: 1264,
    ay: 946,
    area: C83,
    color: pink,
    type: "chalet",
  },
  {
    x: 1397,
    y: 1137,
    title: "C84",
    badge: "",
    ax: 1430,
    ay: 1079,
    area: C84,
    color: pink,
    type: "chalet",
  },
  {
    x: 1512,
    y: 1177,
    title: "C85",
    badge: "",
    ax: 1596,
    ay: 1170,
    area: C85,
    color: pink,
    type: "chalet",
  },
  {
    x: 1452,
    y: 933,
    title: "C86",
    badge: "",
    ax: 1490,
    ay: 969,
    area: C86,
    color: pink,
    type: "chalet",
  },
  {
    x: 1365,
    y: 745,
    title: "C87",
    badge: "",
    ax: 1314,
    ay: 755,
    area: C87,
    color: pink,
    type: "chalet",
  },
  {
    x: 1478,
    y: 632,
    title: "C88",
    badge: "",
    ax: 1427,
    ay: 642,
    area: C88,
    color: pink,
    type: "chalet",
  },
  {
    x: 1373,
    y: 583,
    title: "C89",
    badge: "",
    ax: 1334,
    ay: 615,
    area: C89,
    color: pink,
    type: "chalet",
  },
  {
    x: 1180,
    y: 655,
    title: "C90",
    badge: "",
    ax: 1253,
    ay: 628,
    area: C90,
    color: pink,
    type: "chalet",
  },
  {
    x: 1100,
    y: 715,
    title: "C91",
    badge: "",
    ax: 1154,
    ay: 706,
    area: C91,
    color: pink,
    type: "chalet",
  },
  {
    x: 1145,
    y: 769,
    title: "C92",
    badge: "",
    ax: 1139,
    ay: 804,
    area: C92,
    color: pink,
    type: "chalet",
  },
  {
    x: 970,
    y: 770,
    title: "C93",
    badge: "",
    ax: 1008,
    ay: 807,
    area: C93,
    color: pink,
    type: "chalet",
  },
  {
    x: 791,
    y: 844,
    title: "C94",
    badge: "",
    ax: 731,
    ay: 852,
    area: C94,
    color: pink,
    type: "chalet",
  },
  {
    x: 960,
    y: 688,
    title: "C95",
    badge: "",
    ax: 1023,
    ay: 658,
    area: C95,
    color: pink,
    type: "chalet",
  },
  {
    x: 1106,
    y: 602,
    title: "C96",
    badge: "",
    ax: 1107,
    ay: 557,
    area: C96,
    color: pink,
    type: "chalet",
  },
  {
    x: 1219,
    y: 546,
    title: "C97",
    badge: "",
    ax: 1204,
    ay: 499,
    area: C97,
    color: pink,
    type: "chalet",
  },
  {
    x: 1304,
    y: 510,
    title: "C98",
    badge: "",
    ax: 1287,
    ay: 458,
    area: C98,
    color: pink,
    type: "chalet",
  },
  {
    x: 1353,
    y: 321,
    title: "C99",
    badge: "",
    ax: 1334,
    ay: 267,
    area: C99,
    color: pink,
    type: "chalet",
  },
  {
    x: 1215,
    y: 195,
    title: "C100",
    badge: "",
    ax: 1155,
    ay: 190,
    area: C100,
    color: pink,
    type: "chalet",
  },
  {
    x: 1128,
    y: 260,
    title: "C101",
    badge: "",
    ax: 1075,
    ay: 230,
    area: C101,
    color: pink,
    type: "chalet",
  },
  {
    x: 1104,
    y: 326,
    title: "C102",
    badge: "",
    ax: 1042,
    ay: 311,
    area: C102,
    color: pink,
    type: "chalet",
  },
  {
    x: 1128,
    y: 388,
    title: "C103",
    badge: "",
    ax: 1092,
    ay: 408,
    area: C103,
    color: pink,
    type: "chalet",
  },
  {
    x: 999,
    y: 430,
    title: "C104",
    badge: "",
    ax: 1012,
    ay: 467,
    area: C104,
    color: pink,
    type: "chalet",
  },
  {
    x: 889,
    y: 446,
    title: "C105",
    badge: "",
    ax: 905,
    ay: 486,
    area: C105,
    color: pink,
    type: "chalet",
  },
  {
    x: 781,
    y: 649,
    title: "C106",
    badge: "",
    ax: 799,
    ay: 698,
    area: C106,
    color: pink,
    type: "chalet",
  },
  {
    x: 786,
    y: 552,
    title: "C107",
    badge: "",
    ax: 746,
    ay: 511,
    area: C107,
    color: pink,
    type: "chalet",
  },
  {
    x: 728,
    y: 458,
    title: "C108",
    badge: "",
    ax: 673,
    ay: 492,
    area: C108,
    color: pink,
    type: "chalet",
  },
];

const phase4AreaSetPrevious = [
  // {
  //   x: 586,
  //   y: 2476,
  //   title: "P4-01",
  //   badge: "",
  //   ax: 546,
  //   ay: 2405,
  //   area: P4_1,
  //   color: pink,
  // },
  // {
  //   x: 500,
  //   y: 2397,
  //   title: "P4-02",
  //   badge: "",
  //   ax: 469,
  //   ay: 2333,
  //   area: P4_2,
  //   color: pink,
  // },
  {
    x: 170,
    y: 1815,
    title: "P4-03",
    badge: "",
    ax: 129,
    ay: 1748,
    area: P4_3,
    color: pink,
  },
  {
    x: 326,
    y: 1764,
    title: "P4-04",
    badge: "",
    ax: 257,
    ay: 1708,
    area: P4_4,
    color: pink,
  },
  {
    x: 462,
    y: 1764,
    title: "P4-05",
    badge: "",
    ax: 407,
    ay: 1708,
    area: P4_5,
    color: pink,
  },
  // {
  //   x: 504,
  //   y: 1490,
  //   title: "",
  //   badge: "",
  //   ax: 550,
  //   ay: 1393,
  //   area: P4_6,
  //   color: pink,
  // },
  // {
  //   x: 627,
  //   y: 1481,
  //   title: "P4-07",
  //   badge: "",
  //   ax: 616,
  //   ay: 1416,
  //   area: P4_7,
  //   color: pink,
  // },
  // {
  //   x: 690,
  //   y: 1514,
  //   title: "P4-08",
  //   badge: "",
  //   ax: 682,
  //   ay: 1437,
  //   area: P4_8,
  //   color: pink,
  // },
  {
    x: 856,
    y: 1740,
    title: "P4-09",
    badge: "",
    ax: 813,
    ay: 1661,
    area: P4_9,
    color: pink,
  },
  // {
  //   x: 840,
  //   y: 1476,
  //   title: "P4-10",
  //   badge: "",
  //   ax: 803,
  //   ay: 1404,
  //   area: P4_10,
  //   color: pink,
  // },
  {
    x: 992,
    y: 1688,
    title: "P4-11",
    badge: "",
    ax: 934,
    ay: 1601,
    area: P4_11,
    color: pink,
  },
  {
    x: 1110,
    y: 1643,
    title: "P4-12",
    badge: "",
    ax: 1052,
    ay: 1545,
    area: P4_12,
    color: pink,
  },
  {
    x: 1056,
    y: 1496,
    title: "P4-13",
    badge: "",
    ax: 1012,
    ay: 1433,
    area: P4_13,
    color: pink,
  },
  {
    x: 975,
    y: 1522,
    title: "P4-14",
    badge: "",
    ax: 937,
    ay: 1443,
    area: P4_14,
    color: pink,
  },
  {
    x: 900,
    y: 1563,
    title: "P4-15",
    badge: "",
    ax: 863,
    ay: 1482,
    area: P4_15,
    color: pink,
  },
  {
    x: 832,
    y: 1613,
    title: "P4-16",
    badge: "",
    ax: 796,
    ay: 1523,
    area: P4_16,
    color: pink,
  },
  {
    x: 743,
    y: 1636,
    title: "P4-17",
    badge: "",
    ax: 736,
    ay: 1559,
    area: P4_17,
    color: pink,
  },
  {
    x: 658,
    y: 1606,
    title: "P4-18",
    badge: "",
    ax: 632,
    ay: 1511,
    area: P4_18,
    color: pink,
  },
  {
    x: 578,
    y: 1558,
    title: "P4-19",
    badge: "",
    ax: 565,
    ay: 1430,
    area: P4_19,
    color: pink,
  },
  {
    x: 516,
    y: 1506,
    title: "P4-20",
    badge: "",
    ax: 497,
    ay: 1430,
    area: P4_20,
    color: pink,
  },
  {
    x: 420,
    y: 1524,
    title: "P4-21",
    badge: "",
    ax: 401,
    ay: 1472,
    area: P4_21,
    color: pink,
  },
  {
    x: 449,
    y: 1647,
    title: "P4-22",
    badge: "",
    ax: 391,
    ay: 1587,
    area: P4_22,
    color: pink,
  },
  {
    x: 275,
    y: 1676,
    title: "P4-23",
    badge: "",
    ax: 163,
    ay: 1600,
    area: P4_23,
    color: pink,
  },
  {
    x: 219,
    y: 1588,
    title: "P4-24",
    badge: "",
    ax: 163,
    ay: 1535,
    area: P4_24,
    color: pink,
  },
  {
    x: 241,
    y: 1481,
    title: "P4-25",
    badge: "",
    ax: 200,
    ay: 1437,
    area: P4_25,
    color: pink,
  },
  {
    x: 257,
    y: 1384,
    title: "P4-26",
    badge: "",
    ax: 216,
    ay: 1362,
    area: P4_26,
    color: pink,
  },
  {
    x: 262,
    y: 1309,
    title: "P4-27",
    badge: "",
    ax: 228,
    ay: 1288,
    area: P4_27,
    color: pink,
  },
  {
    x: 277,
    y: 1240,
    title: "P4-28",
    badge: "",
    ax: 240,
    ay: 1205,
    area: P4_28,
    color: pink,
  },
  {
    x: 295,
    y: 1164,
    title: "P4-29",
    badge: "",
    ax: 254,
    ay: 1131,
    area: P4_29,
    color: pink,
  },
  {
    x: 300,
    y: 1091,
    title: "P4-30",
    badge: "",
    ax: 266,
    ay: 1058,
    area: P4_30,
    color: pink,
  },
  {
    x: 337,
    y: 1022,
    title: "P4-31",
    badge: "",
    ax: 278,
    ay: 853,
    area: P4_31,
    color: pink,
  },
  {
    x: 306,
    y: 932,
    title: "P4-32",
    badge: "",
    ax: 278,
    ay: 853,
    area: P4_32,
    color: pink,
  },
  {
    x: 481,
    y: 1107,
    title: "P4-33",
    badge: "",
    ax: 442,
    ay: 1068,
    area: P4_33,
    color: pink,
  },
  {
    x: 464,
    y: 1175,
    title: "P4-34",
    badge: "",
    ax: 424,
    ay: 1142,
    area: P4_34,
    color: pink,
  },
  {
    x: 460,
    y: 1251,
    title: "P4-35",
    badge: "",
    ax: 406,
    ay: 1219,
    area: P4_35,
    color: pink,
  },
  {
    x: 447,
    y: 1322,
    title: "P4-36",
    badge: "",
    ax: 396,
    ay: 1296,
    area: P4_36,
    color: pink,
  },
  {
    x: 429,
    y: 1396,
    title: "P4-37",
    badge: "",
    ax: 390,
    ay: 1371,
    area: P4_37,
    color: pink,
  },
  {
    x: 558,
    y: 1334,
    title: "P4-38",
    badge: "",
    ax: 543,
    ay: 1247,
    area: P4_38,
    color: pink,
  },
  {
    x: 654,
    y: 1303,
    title: "P4-39",
    badge: "",
    ax: 627,
    ay: 1256,
    area: P4_39,
    color: pink,
  },
  {
    x: 691,
    y: 1458,
    title: "P4-40",
    badge: "",
    ax: 674,
    ay: 1358,
    area: P4_40,
    color: pink,
  },
  // {
  //   x: 702,
  //   y: 1172,
  //   title: "P4-41",
  //   badge: "",
  //   ax: 685,
  //   ay: 1067,
  //   area: P4_41,
  //   color: pink,
  // },
  // {
  //   x: 749,
  //   y: 1115,
  //   title: "P4-42",
  //   badge: "",
  //   ax: 729,
  //   ay: 1061,
  //   area: P4_42,
  //   color: pink,
  // },
  {
    x: 935,
    y: 1382,
    title: "P4-43",
    badge: "",
    ax: 836,
    ay: 1332,
    area: P4_43,
    color: pink,
  },
  {
    x: 880,
    y: 1326,
    title: "P4-44",
    badge: "",
    ax: 807,
    ay: 1269,
    area: P4_44,
    color: pink,
  },
  {
    x: 836,
    y: 1271,
    title: "P4-45",
    badge: "",
    ax: 774,
    ay: 1219,
    area: P4_45,
    color: pink,
  },
  {
    x: 840,
    y: 1179,
    title: "P4-46",
    badge: "",
    ax: 811,
    ay: 1064,
    area: P4_46,
    color: pink,
  },
  {
    x: 750,
    y: 1120,
    title: "P4-47",
    badge: "",
    ax: 725,
    ay: 1058,
    area: P4_47,
    color: pink,
  },
  {
    x: 663,
    y: 1155,
    title: "P4-48",
    badge: "",
    ax: 652,
    ay: 1071,
    area: P4_48,
    color: pink,
  },
  {
    x: 581,
    y: 1200,
    title: "P4-49",
    badge: "",
    ax: 575,
    ay: 1089,
    area: P4_49,
    color: pink,
  },
  // {
  //   x: 867,
  //   y: 1637,
  //   title: "P4-50",
  //   badge: "",
  //   ax: 838,
  //   ay: 1545,
  //   area: P4_50,
  //   color: pink,
  // },
  {
    x: 1030,
    y: 1887,
    title: "P4-51",
    badge: "",
    ax: 1009,
    ay: 1801,
    area: P4_51,
    color: pink,
  },
  {
    x: 1093,
    y: 1830,
    title: "P4-52",
    badge: "",
    ax: 1083,
    ay: 1777,
    area: P4_52,
    color: pink,
  },
  {
    x: 1187,
    y: 1835,
    title: "P4-53",
    badge: "",
    ax: 1154,
    ay: 1746,
    area: P4_53,
    color: pink,
  },
  {
    x: 1240,
    y: 1754,
    title: "P4-54",
    badge: "",
    ax: 1196,
    ay: 1698,
    area: P4_54,
    color: pink,
  },
  {
    x: 1271,
    y: 1663,
    title: "P4-55",
    badge: "",
    ax: 1225,
    ay: 1571,
    area: P4_55,
    color: pink,
  },
  {
    x: 1246,
    y: 1561,
    title: "P4-56",
    badge: "",
    ax: 1200,
    ay: 1491,
    area: P4_56,
    color: pink,
  },
  {
    x: 1224,
    y: 1491,
    title: "P4-57",
    badge: "",
    ax: 1165,
    ay: 1409,
    area: P4_57,
    color: pink,
  },
  // {
  //   x: 1266,
  //   y: 1432,
  //   title: "P4-58",
  //   badge: "",
  //   ax: 1217,
  //   ay: 1386,
  //   area: P4_58,
  //   color: pink,
  // },
  {
    x: 1143,
    y: 1352,
    title: "P4-59",
    badge: "",
    ax: 1097,
    ay: 1233,
    area: P4_59,
    color: pink,
  },
  {
    x: 1120,
    y: 1210,
    title: "P4-60",
    badge: "",
    ax: 1039,
    ay: 1028,
    area: P4_60,
    color: pink,
  },
  {
    x: 1116,
    y: 1089,
    title: "P4-61",
    badge: "",
    ax: 943,
    ay: 954,
    area: P4_61,
    color: pink,
  },
  {
    x: 1075,
    y: 1022,
    title: "P4-62",
    badge: "",
    ax: 927,
    ay: 897,
    area: P4_62,
    color: pink,
  },
  {
    x: 958,
    y: 1002,
    title: "P4-63",
    badge: "",
    ax: 892,
    ay: 947,
    area: P4_63,
    color: pink,
  },
  {
    x: 876,
    y: 964,
    title: "P4-64",
    badge: "",
    ax: 840,
    ay: 836,
    area: P4_64,
    color: pink,
  },
  {
    x: 788,
    y: 871,
    title: "P4-65",
    badge: "",
    ax: 761,
    ay: 803,
    area: P4_65,
    color: pink,
  },
  {
    x: 699,
    y: 915,
    title: "P4-66",
    badge: "",
    ax: 683,
    ay: 803,
    area: P4_66,
    color: pink,
  },
  {
    x: 617,
    y: 956,
    title: "P4-67",
    badge: "",
    ax: 606,
    ay: 830,
    area: P4_67,
    color: pink,
  },
  {
    x: 538,
    y: 994,
    title: "P4-68",
    badge: "",
    ax: 533,
    ay: 846,
    area: P4_68,
    color: pink,
  },
  {
    x: 460,
    y: 937,
    title: "P4-69",
    badge: "",
    ax: 427,
    ay: 876,
    area: P4_69,
    color: pink,
  },
  {
    x: 427,
    y: 834,
    title: "P4-70",
    badge: "",
    ax: 377,
    ay: 779,
    area: P4_70,
    color: pink,
  },
  {
    x: 411,
    y: 707,
    title: "P4-71",
    badge: "",
    ax: 374,
    ay: 672,
    area: P4_71,
    color: pink,
  },
  {
    x: 551,
    y: 681,
    title: "P4-72",
    badge: "",
    ax: 510,
    ay: 630,
    area: P4_72,
    color: pink,
  },
  {
    x: 642,
    y: 713,
    title: "P4-73",
    badge: "",
    ax: 643,
    ay: 593,
    area: P4_73,
    color: pink,
  },
  {
    x: 719,
    y: 649,
    title: "P4-74",
    badge: "",
    ax: 722,
    ay: 576,
    area: P4_74,
    color: pink,
  },
  {
    x: 800,
    y: 709,
    title: "P4-75",
    badge: "",
    ax: 800,
    ay: 619,
    area: P4_75,
    color: pink,
  },
  {
    x: 891,
    y: 747,
    title: "P4-76",
    badge: "",
    ax: 890,
    ay: 656,
    area: P4_76,
    color: pink,
  },
  {
    x: 981,
    y: 762,
    title: "P4-77",
    badge: "",
    ax: 964,
    ay: 656,
    area: P4_77,
    color: pink,
  },
  {
    x: 1098,
    y: 773,
    title: "P4-78",
    badge: "",
    ax: 1021,
    ay: 626,
    area: P4_78,
    color: pink,
  },
  {
    x: 814,
    y: 551,
    title: "P4-79",
    badge: "",
    ax: 771,
    ay: 443,
    area: P4_79,
    color: pink,
  },
  {
    x: 913,
    y: 524,
    title: "P4-80",
    badge: "",
    ax: 903,
    ay: 435,
    area: P4_80,
    color: pink,
  },
  {
    x: 1032,
    y: 506,
    title: "P4-81",
    badge: "",
    ax: 1005,
    ay: 444,
    area: P4_81,
    color: pink,
  },
  {
    x: 1095,
    y: 395,
    title: "P4-82",
    badge: "",
    ax: 1032,
    ay: 290,
    area: P4_82,
    color: pink,
  },
  {
    x: 955,
    y: 334,
    title: "P4-83",
    badge: "",
    ax: 869,
    ay: 219,
    area: P4_83,
    color: pink,
  },
  {
    x: 851,
    y: 365,
    title: "P4-84",
    badge: "",
    ax: 810,
    ay: 261,
    area: P4_84,
    color: pink,
  },
  // {
  //   x: 949,
  //   y: 381,
  //   title: "P4-85",
  //   badge: "",
  //   ax: 934,
  //   ay: 301,
  //   area: P4_85,
  //   color: pink,
  // },
  // {
  //   x: 1061,
  //   y: 364,
  //   title: "P4-86",
  //   badge: "",
  //   ax: 1037,
  //   ay: 311,
  //   area: P4_86,
  //   color: pink,
  // },
  {
    x: 553,
    y: 564,
    title: "P4-87",
    badge: "",
    ax: 505,
    ay: 532,
    area: P4_87,
    color: pink,
  },
  // {
  //   x: 980,
  //   y: 211,
  //   title: "P4-88",
  //   badge: "",
  //   ax: 903,
  //   ay: 107,
  //   area: P4_88,
  //   color: pink,
  // },
  // {
  //   x: 887,
  //   y: 250,
  //   title: "P4-89",
  //   badge: "",
  //   ax: 850,
  //   ay: 145,
  //   area: P4_89,
  //   color: pink,
  // },
  // {
  //   x: 132,
  //   y: 1744,
  //   title: "P4-90",
  //   badge: "",
  //   ax: 110,
  //   ay: 1595,
  //   area: P4_90,
  //   color: pink,
  // },
  // {
  //   x: 192,
  //   y: 1824,
  //   title: "P4-91",
  //   badge: "",
  //   ax: 180,
  //   ay: 1664,
  //   area: P4_91,
  //   color: pink,
  // },
  // {
  //   x: 263,
  //   y: 1777,
  //   title: "P4-92",
  //   badge: "",
  //   ax: 264,
  //   ay: 1652,
  //   area: P4_92,
  //   color: pink,
  // },
  // {
  //   x: 332,
  //   y: 1720,
  //   title: "P4-93",
  //   badge: "",
  //   ax: 322,
  //   ay: 1644,
  //   area: P4_93,
  //   color: pink,
  // },
  // {
  //   x: 311,
  //   y: 2035,
  //   title: "P4-94",
  //   badge: "",
  //   ax: 250,
  //   ay: 1902,
  //   area: P4_94,
  //   color: pink,
  // },
  // {
  //   x: 349,
  //   y: 2234,
  //   title: "P4-95",
  //   badge: "",
  //   ax: 281,
  //   ay: 2150,
  //   area: P4_95,
  //   color: pink,
  // },
  // {
  //   x: 280,
  //   y: 1745,
  //   title: "P4-96",
  //   badge: "",
  //   ax: 266,
  //   ay: 1664,
  //   area: P4_96,
  //   color: pink,
  // },
  // {
  //   x: 369,
  //   y: 1688,
  //   title: "P4-97",
  //   badge: "",
  //   ax: 354,
  //   ay: 1640,
  //   area: P4_97,
  //   color: pink,
  // },
  // {
  //   x: 379,
  //   y: 1756,
  //   title: "P4-98",
  //   badge: "",
  //   ax: 353,
  //   ay: 1713,
  //   area: P4_98,
  //   color: pink,
  // },
  // {
  //   x: 379,
  //   y: 1832,
  //   title: "P4-99",
  //   badge: "",
  //   ax: 350,
  //   ay: 1787,
  //   area: P4_99,
  //   color: pink,
  // },
  // {
  //   x: 398,
  //   y: 2038,
  //   title: "P4-100",
  //   badge: "",
  //   ax: 336,
  //   ay: 1904,
  //   area: P4_100,
  //   color: pink,
  // },
  // {
  //   x: 436,
  //   y: 2239,
  //   title: "P4-101",
  //   badge: "",
  //   ax: 367,
  //   ay: 2154,
  //   area: P4_101,
  //   color: pink,
  // },
];

export {
  areaSet,
  phase2AreaSet,
  phase3AreaSet,
  phase4AreaSet,
  phase4DomeAreaSet,
  phase4NordAreaSet,
  phase4AreaSetPrevious,
};
