import React, { useState } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import { STGabrielAreaMap } from "../../index";
import { stGabrielAreaSet } from "../../../utils/stComeData";

const STGabrielMapMain = () => {
  const [value, setValue] = useState({
    scale: 0.4,
    translation: {
      x: (window.innerWidth - 870) / 2,
      y: 30,
    },
  });

  const openLink = "https://share.hsforms.com/288tt5wkWSyu5Ue1vaOmM3w43zf4";
  return (
    <>
      <div style={{ backgroundColor: "#F0FFF1", height: "100%" }}>
        <MapInteractionCSS
          value={value}
          onChange={(value) => {
            value.scale < 2.5
              ? setValue(value)
              : setValue({ scale: 2.5, translation: value.translation });
          }}
          minScale={0.2}
        >
          <div>
            {stGabrielAreaSet.map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  style={{
                    position: "absolute",
                    top: item.ay,
                    left: item.ax,
                  }}
                  onClick={() => window.open(openLink)}
                  onTouchEnd={() => window.open(openLink)}
                >
                  <item.area fill={item.colors} />
                </div>
              );
            })}

            <STGabrielAreaMap />

            {stGabrielAreaSet.map((item, index) => {
              if (item.title) {
                return (
                  <div
                    key={index.toString()}
                    className="st-gabriel-map-letter"
                    style={{
                      position: "absolute",
                      top: item.y,
                      left: item.x,
                      zIndex: 1000,
                    }}
                    onClick={() => window.open(openLink)}
                    onTouchEnd={() => window.open(openLink)}
                  >
                    {item.title}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </MapInteractionCSS>
      </div>
    </>
  );
};

export default STGabrielMapMain;
