import React from "react";
import { STComeMapMain, PineMapMain, STGabrielMapMain } from "../components";
import { constant } from "../utils/constant";

const Map = () => {
  document.title = window.location.hostname?.includes(
    "carte.domainestgabriel.com"
  )
    ? "Domaine St. Gabriel"
    : process.env.REACT_APP_TITLE;
  if (window.location.hostname?.includes("carte.domainestgabriel.com")) {
    return <STGabrielMapMain />;
  } else if (process.env.REACT_APP_MAP === constant.pinehill) {
    return <PineMapMain />;
  }
  return <STComeMapMain />;
};

export default Map;
