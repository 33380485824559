import React, { useState, useEffect } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ref,
  onValue,
  child,
  query,
  orderByChild,
  get,
  equalTo,
} from "firebase/database";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { dealsRef, rdb, tables } from "../../../services/firebase";
import {
  STComeAreaMap,
  Header,
  GroundCondition,
  Terrains,
  ViewLand,
  SubmitDetails,
  // STComePath,
  About,
  Spinner,
} from "../../index";
import { stcomeAreaSet1 } from "../../../utils/stComeData";
import { colors } from "../../../utils/theme";
import {
  selectPipeline,
  loadReservationScript,
  setTrackingCode,
  filterString,
} from "../../../utils/helper";
import { constant } from "../../../utils/constant";
import strings from "../../../utils/fr.json";
import { areaRule } from "../pineMap/areaRule";

const STComePhase1 = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { setIdentity, setPathPageView, setTrackPageView } = useTrackingCode();
  const [isLandModal, setIsLandModal] = useState(false);
  const [planVisitModal, setPlanVisitModal] = useState(false);
  const [value, setValue] = useState({
    scale: 0.5,
    translation: {
      x: window.innerWidth < 600 ? -parseInt(window.innerWidth * 1) : 0,
      y: 0,
    },
    // scale: (window.innerWidth * 0.01) / 25,
    // translation: {
    //   x:
    //     location?.state?.phase === constant.Phase1
    //       ? 0
    //       : parseInt(window.innerWidth * 0.1),
    //   y:
    //     location?.state?.phase === constant.Phase1
    //       ? 0
    //       : -parseInt(window.innerHeight * 0.35),
  });
  const [loading, setLoading] = useState(false);
  const [isTerrains, setIsTerrains] = useState(false);
  const [activeArea, setActiveArea] = useState(null);
  const [isSubmitDetails, setIsSubmitDetails] = useState(false);
  const [dragheadercompass, setDragheadercompass] = useState(0);
  const [dealsData, setDealsData] = useState(stcomeAreaSet1);
  const [hideonDrag, sethideonDrag] = useState("");
  const [totalLand, setTotalLand] = useState([]);
  const [aboutModal, setAboutModal] = useState(false);
  const [disponibles, setDisponibles] = useState(0);
  const [isFavourite, setFavourite] = useState(false);
  const [favourite_doc_id, setFavouriteDocId] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      sethideonDrag("");
    }, 1500);
  });

  useEffect(() => {
    setTrackingCode(setIdentity, setPathPageView, setTrackPageView);
    if (id) {
      setIsTerrains(true);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      let phaseCheck = constant.phase1;

      const starCountRef = query(
        dealsRef,
        orderByChild("pipelineId"),
        equalTo(selectPipeline(phaseCheck, constant.stcome))
      );
      onValue(starCountRef, async (snapshot) => {
        const newDealsData = snapshot.val();
        var newData = [];
        var totalLands = [];
        setLoading(false);

        const dealsInfo = await Object.entries(newDealsData);
        // var dataaa = dealsInfo?.filter((item) =>
        //   item[1]?.properties?.dealname?.value?.includes("C1-15")
        // );

        for (let index = 0; index < stcomeAreaSet1.length; index++) {
          const element = stcomeAreaSet1[index];
          var data = [];
          let color = colors.grey;

          for (let i = 0; i < dealsInfo?.length; i++) {
            const deal = dealsInfo[i];
            var newValue =
              filterString(
                deal[1]?.properties?.identification_du_lot_ph?.value
              ) || deal[1]?.properties?.dealname?.value?.substr(0, 5).trim();
            if (
              element.title?.toLowerCase().trim() ===
              newValue?.toLowerCase().trim()
            ) {
              data = [...data, { ...deal[1], key: deal[0] }];
            }
          }

          const checkStatus =
            data[0]?.properties?.statut_des_promesses_d_achat?.value;
          const reservationStatus =
            data[0]?.properties?.reservation_via_la_carte_interactive?.value;
          const dealStageValue = data[0]?.properties.dealstage?.value;
          const stageValue =
            constant.dealsStage.stcome[phaseCheck.replace(/ +/g, "")];
          color = areaRule(
            checkStatus,
            reservationStatus,
            dealStageValue,
            stageValue
          );

          var count = checkStatus
            ? checkStatus === strings.Status.complete
              ? 3
              : checkStatus === strings.Status.row2
              ? 2
              : 1
            : 0;
          if (
            activeArea &&
            data.length > 1 &&
            activeArea.deals[0]?.properties?.dealname?.value ===
              data[0]?.properties?.dealname?.value &&
            color === colors.pink &&
            isSubmitDetails
          ) {
            setIsSubmitDetails(false);
            setIsTerrains(false);
            setAboutModal(false);
          }
          newData = [
            ...newData,
            {
              ...element,
              colors: color,
              deals: data,
              count: count,
            },
          ];
          var disponibles = newData.filter(
            (item) => item.colors === colors.green
            // || item.colors === colors.yellow
          );

          totalLands =
            data.length > 0 ? [...totalLands, { colors: color }] : totalLands;

          setDisponibles(disponibles.length);
          setTotalLand(totalLands);
        }
        setDealsData(newData);
        setLoading(false);
      });
    }
    fetchData();
  }, [activeArea, isSubmitDetails, location]);

  const handleClick = () => {
    sethideonDrag("hide");
  };

  const checkFavourite = async (areas, item) => {
    var userID = await localStorage.getItem("@userId");
    var isGuestLogin = await localStorage.getItem("@guest");
    if (isGuestLogin !== "true") {
      var landFavId = `${userID}${areas?.deals[0]?.dealId}`;
      var wishlistItem = await get(
        child(ref(rdb), `${tables.wishlist}${landFavId}`)
      );
      var isExist = wishlistItem.exists();
      setFavourite(isExist);
      setFavouriteDocId(isExist ? landFavId : 0);
    }
    setActiveArea({ ...item, color: item.colors });
    setIsLandModal(!isLandModal);
  };

  return (
    <>
      <Spinner loading={loading} />
      <div style={{ backgroundColor: "#B4C9B3", height: "100%" }}>
        <Header
          isBack={true}
          isCompass={true}
          dragheadercompass={(data) => setDragheadercompass(data)}
          hide={hideonDrag}
          dateLimit={location?.state?.dateLimit}
          timeLimit={location?.state?.timeLimit}
        />
        <MapInteractionCSS
          value={value}
          onChange={(value) => {
            handleClick();
            setValue(value);
          }}
          minScale={0.3}
        >
          <div
            style={{
              transform: `rotate(${dragheadercompass}deg)`,
            }}
          >
            {dealsData.map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  style={{
                    position: "absolute",
                    top: item.ay,
                    left: item.ax,
                  }}
                >
                  <item.area fill={item.colors} />
                </div>
              );
            })}

            <div
              style={{
                position: "absolute",
                top: 10,
                left: 590,
              }}
            >
              <STComeAreaMap />
            </div>

            {dealsData.map((item, index) => {
              if (item.title) {
                return (
                  <div
                    key={index.toString()}
                    className="map-letter st-come-map-letter"
                    style={{
                      position: "absolute",
                      top: item.y,
                      left: item.x,
                      zIndex: 1000,
                    }}
                    onClick={() => {
                      if (item.colors !== colors.grey) {
                        checkFavourite({ ...item, color: item.colors }, item);
                      }
                    }}
                    onTouchEnd={() => {
                      if (item.colors !== colors.grey) {
                        checkFavourite({ ...item, color: item.colors }, item);
                      }
                    }}
                  >
                    {item.title}
                    {item.badge !== "" && (
                      <div className="chat-badge">{item.badge}</div>
                    )}
                  </div>
                );
              }
              return null;
            })}
            {/* <div
              style={{
                position: "absolute",
                top: 524,
                left: 26.31,
              }}
            >
              <STComePath />
            </div> */}
          </div>
        </MapInteractionCSS>
      </div>
      <GroundCondition
        hide={hideonDrag}
        disponibles={disponibles}
        totalLand={totalLand.length}
      />
      <ViewLand
        show={isLandModal}
        handleTerrain={() => {
          setIsLandModal(!isLandModal);
          setIsTerrains(!isTerrains);
          navigate(`/phase1/${activeArea?.deals[0]?.dealId}`);
        }}
        handleLand={() => setIsLandModal(!isLandModal)}
        activeArea={activeArea}
        phaseMap={{ phase: constant.phase1, map: constant.stcome }}
        isFavourite={isFavourite}
        favouriteDocId={favourite_doc_id}
        dateLimit={location?.state?.dateLimit}
        timeLimit={location?.state?.timeLimit}
      />
      {isTerrains ? (
        <Terrains
          show={isTerrains}
          handleTerrains={() => setIsTerrains(!isTerrains)}
          activeArea={activeArea}
          handleSubmitDetails={() => setIsSubmitDetails(!isSubmitDetails)}
          location={location}
          setPlanVisitModal={() => {
            setPlanVisitModal(true);
            loadReservationScript();
          }}
          dateLimit={location?.state?.dateLimit}
          timeLimit={location?.state?.timeLimit}
          // mapName={"phase-1"}
        />
      ) : null}
      <div
        style={{ display: planVisitModal ? "block" : "none" }}
        className="event-modal"
        onClick={() => setPlanVisitModal(false)}
      >
        <div className="event-dialog">
          <div className="event-content">
            <div id="eventbrite-widget-container-408850391527"></div>
          </div>
        </div>
      </div>
      {isSubmitDetails ? (
        <SubmitDetails
          // show={true}
          activeArea={activeArea}
          show={isSubmitDetails}
          phaseMap={{ phase: constant.phase1, map: constant.stcome }}
          handleSubmitDetails={() => setIsSubmitDetails(!isSubmitDetails)}
          handleTerrains={() => setIsTerrains(!isTerrains)}
          handleAbout={() => {
            setAboutModal(!aboutModal);
            navigate(-1);
          }}
        />
      ) : null}

      <About show={aboutModal} handleAbout={() => setAboutModal(!aboutModal)} />
    </>
  );
};

export default STComePhase1;
