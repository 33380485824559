import React, { useEffect, useState } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import { useLocation } from "react-router-dom";
import { onValue, query, orderByChild, equalTo } from "firebase/database";
import { dealsRef, projectRef } from "../../../services/firebase";
import {
  Header,
  Footer,
  STComeMainMap,
  Menu,
  ViewMore,
  LandReserch,
  Loader,
  ForgetPassword,
  // RegisterForm,
  SignupForm,
  Favourite,
  Promises,
  Spinner,
  NewLogin,
} from "../../index";
import { stComePhase1, stComePhase2 } from "../../../assets/images";
import { stcomeAreaSet1, stcomeAreaSet2 } from "../../../utils/stComeData";
import { colors } from "../../../utils/theme";
import { filterString, selectPipeline } from "../../../utils/helper";
import { constant } from "../../../utils/constant";
import strings from "../../../utils/fr.json";
import { areaRule } from "../pineMap/areaRule";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const STComeMapMain = () => {
  const { setIdentity, setPathPageView, setTrackPageView } = useTrackingCode();
  var location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isMenuPage, setIsMenuPage] = useState(false);
  const [totalLand, setTotalLand] = useState([]);
  const [value, setValue] = useState({
    scale: 1.9,
    translation: { x: -600, y: -500 },
  });
  const loginAlready = localStorage.getItem("@login");
  const [disponibles, setDisponibles] = useState(0);
  const [footerActive, setFooterActive] = useState(0);
  const [openPhaseModal, setOpenPhaseModal] = useState(false);
  const [phase, setPhase] = useState(1);
  const [isLoad, setIsLoad] = useState(location?.state?.isBack ? false : true);
  const [isResearchModal, setIsResearchModal] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(!(loginAlready === "true"));
  const [isForgotPassModal, setIsForgotPassModal] = useState(false);
  const [isRegisterForm, setIsRegisterForm] = useState(false);
  const [isFavouriteModal, setIsFavouriteModal] = useState(false);
  const [isPromiseModal, setIsPromiseModal] = useState(false);
  const [dateLimit, setDateLimit] = useState();
  const [timeLimit, setTimeLimit] = useState();
  const [prefillEmail, setPreFillEmail] = useState("");

  const phases = [
    {
      y: 250,
      x: 230,
      phase: stComePhase1,
      name: constant.phase1,
    },
    {
      y: 310,
      x: 170,
      phase: stComePhase2,
      name: constant.phase2,
    },
  ];
  var isGuestLogin = localStorage.getItem("@guest");
  useEffect(() => {
    // window.addEventListener("resize", mapResize);
    window.addEventListener("load", mapResize);

    setTimeout(() => {
      setIsLoad(false);
    }, 1500);
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDataString = await localStorage.getItem("@userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          const userEmail = userData.email;
          setIdentity(userEmail);
          setPathPageView(`${location?.pathname}`);
          setTrackPageView();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData(); // Call the function inside useEffect
  }, [loginAlready]);

  const mapResize = () => {
    const screenWidth = window.screen.width;
    if (screenWidth < 576) {
      //Mobile
      setValue({
        scale: 1.25,
        translation: { x: 200, y: -225 },
      });
    } else if (screenWidth >= 576 && screenWidth < 768) {
      //Small
      setValue({
        scale: 1,
        translation: { x: 125, y: -320 },
      });
    } else if (screenWidth >= 768 && screenWidth < 992) {
      //Medium
      setValue({
        scale: 1.25,
        translation: { x: -70, y: -500 },
      });
    } else if (screenWidth >= 992 && screenWidth < 1200) {
      //Large
      setValue({
        scale: 1.9,
        translation: { x: -200, y: -450 },
      });
    } else if (screenWidth >= 1200 && screenWidth < 1400) {
      // X Large
      setValue({
        scale: 2,
        translation: { x: -300, y: -520 },
      });
    } else if (screenWidth >= 1400 && screenWidth < 1600) {
      // 2X Large
      setValue({
        scale: 2,
        translation: { x: -400, y: -525 },
      });
    } else if (screenWidth >= 1600) {
      // 3X Large
      setValue({
        scale: 1.9,
        translation: { x: -600, y: -500 },
      });
    }
  };

  const getDealsData = async (phaseType, ind) => {
    setLoading(true);
    const queryData = query(
      dealsRef,
      orderByChild("pipelineId"),
      equalTo(selectPipeline(phaseType, constant.stcome))
    );
    onValue(
      queryData,
      async (snapshot) => {
        const newDealsData = snapshot.val();
        var newData = [];
        var totalLands = [];
        const dealsInfo = await Object.values(newDealsData);
        // var dealsInfo = await Object.values(newDealsData)?.filter(
        //   (item) =>
        //     item?.properties?.pipeline?.value ===
        //     selectPipeline(phaseType, constant.stcome)
        //    ||
        // item?.properties?.pipeline ===
        //   selectPipeline(constant.phase2, constant.stcome)
        // );
        getPipelineData(dealsInfo[1]);
        var areaData =
          phaseType === constant.phase1 ? stcomeAreaSet1 : stcomeAreaSet2;
        for (let index = 0; index < areaData.length; index++) {
          const element = areaData[index];
          var data = [];
          let color = colors.grey;
          for (let i = 0; i < dealsInfo.length; i++) {
            const deal = dealsInfo[i];

            var newValue =
              filterString(deal?.properties?.identification_du_lot_ph?.value) ||
              deal?.properties?.dealname?.value.substr(0, 5).trim();
            if (element.title === newValue) {
              data = [...data, deal];
            }
          }

          const checkStatus =
            data[0]?.properties?.statut_des_promesses_d_achat?.value;
          const reservationStatus =
            data[0]?.properties?.reservation_via_la_carte_interactive?.value;
          const dealStageValue = data[0]?.properties.dealstage?.value;
          const stageValue =
            constant.dealsStage.stcome[phaseType.replace(/ +/g, "")];

          color = areaRule(
            checkStatus,
            reservationStatus,
            dealStageValue,
            stageValue
          );
          var count = checkStatus
            ? checkStatus === strings.Status.complete
              ? 3
              : checkStatus === strings.Status.row2
              ? 2
              : 1
            : 0;
          totalLands =
            data.length > 0 ? [...totalLands, { colors: color }] : totalLands;
          newData = [
            ...newData,
            {
              ...element,
              colors: color,
              deals: data,
              count: count,
            },
          ];

          var disponibles = newData.filter(
            (item) => item.colors === colors.green
            // || item.colors === colors.yellow
          );
          setTotalLand(totalLands);
          setDisponibles(disponibles.length);
          setPhase(ind + 1);
          setLoading(false);
          // setOpenPhaseModal(!openPhaseModal);
        }
      },
      { onlyOnce: true }
    );
  };

  const getPipelineData = (deals) => {
    const queryData = query(
      projectRef,
      orderByChild("pipelineId"),
      equalTo(deals.pipelineId)
    );
    onValue(queryData, async (snapshot) => {
      var projectsData = snapshot.val() || {};
      projectsData = Object.values(projectsData) || [];
      setTimeout(() => {
        setOpenPhaseModal(!openPhaseModal);
      }, 500);
      if (projectsData.length > 0) {
        setDateLimit(projectsData[0]?.date_limit);
        setTimeLimit(projectsData[0]?.time_limit);
      }
    });
  };

  const setFooterHandle = (index) => {
    setFooterActive(index);
    // setFooterActive(index);
    // setIsResearchModal(index === 1 ? !isResearchModal : false);
    if (index === 0) {
      togglePromiseModal();
      setIsFavouriteModal(false);
      setIsResearchModal(false);
    } else {
      toggleFavouriteModal();
      setIsPromiseModal(false);
      setIsResearchModal(false);
    }
  };

  const toggleFavouriteModal = async () => {
    setIsFavouriteModal(true);
  };

  const togglePromiseModal = async () => {
    setIsPromiseModal(!isPromiseModal);
  };

  const togglePromiseModal1 = async () => {
    setIsPromiseModal(!isPromiseModal);
  };

  const toggleLogin = () => {
    setIsLoginModal(!isLoginModal);
  };

  const handleLogin = () => {
    toggleLogin();
    localStorage.setItem("@login", true);
  };

  const handleForgotPassword = () => {
    toggleLogin();
    toggleForgotPass();
  };

  const toggleForgotPass = () => {
    setIsForgotPassModal(!isForgotPassModal);
  };

  const toggleRegisterForm = () => {
    setIsRegisterForm(!isRegisterForm);
  };

  const handleRegisterForm = (email = "") => {
    toggleLogin();
    toggleRegisterForm();
    if (email) {
      setPreFillEmail(email);
    }
  };

  const handleLogout = () => {
    setIsLoginModal(true);
  };

  if (isLoad) {
    return <Loader />;
  } else {
    return (
      <>
        <Spinner loading={loading} />
        <div
          className="st-come-main-map"
          style={{ backgroundColor: "#737d6f" }}
        >
          <Header handleLogout={handleLogout} />
          <div className="map-center">
            <MapInteractionCSS
              value={value}
              onChange={(value) => {
                value.scale < 2.5
                  ? setValue(value)
                  : setValue({ scale: 2.5, translation: value.translation });
              }}
              minScale={0.5}
            >
              <div className="position-relative">
                <STComeMainMap />
                {phases.map((item, index) => {
                  return (
                    <div
                      key={index.toString()}
                      style={{
                        position: "absolute",
                        top: item.y,
                        left: item.x,
                      }}
                      onClick={() => {
                        getDealsData(item.name, index);
                      }}
                      onTouchEnd={() => {
                        getDealsData(item.name, index);
                      }}
                    >
                      <img
                        src={item.phase}
                        fluid
                        alt=""
                        style={{ width: 80, height: 80 }}
                      />
                      {/* <item.phase /> */}
                    </div>
                  );
                })}
              </div>
            </MapInteractionCSS>
          </div>
        </div>
        {isMenuPage ? (
          <Menu
            show={isMenuPage}
            handleMenu={() => setIsMenuPage(!isMenuPage)}
          />
        ) : null}
        {openPhaseModal ? (
          <ViewMore
            show={openPhaseModal}
            handlePhases={() => setOpenPhaseModal(!openPhaseModal)}
            phase={phase}
            zoom={value.scale}
            disponibles={disponibles}
            totalLand={totalLand.length}
            timeLimit={timeLimit}
            dateLimit={dateLimit}
          />
        ) : null}
        {isResearchModal ? (
          <LandReserch
            show={isResearchModal}
            handleResearch={() => setIsResearchModal(!isResearchModal)}
          />
        ) : null}
        {isPromiseModal ? (
          <Promises
            show={isPromiseModal}
            togglePromiseModal={togglePromiseModal1}
            closePromiseModal={() => setIsPromiseModal(false)}
          />
        ) : null}
        {isFavouriteModal ? (
          <Favourite
            show={isFavouriteModal}
            toggleFavouriteModal={toggleFavouriteModal}
            location={location}
            closeFavouriteModal={() => setIsFavouriteModal(false)}
          />
        ) : null}
        {isLoginModal ? (
          <NewLogin
            show={isLoginModal}
            toggleLogin={toggleLogin}
            prefillEmail={prefillEmail}
            handleForgotPassword={handleForgotPassword}
            handleRegisterForm={handleRegisterForm}
            handleLogin={handleLogin}
            handleLoader={(isOn) => setLoading(isOn)}
            handlePrefillEmail={() => setPreFillEmail("")}
          />
        ) : null}
        {isForgotPassModal ? (
          <ForgetPassword
            show={isForgotPassModal}
            toggleForgotPass={toggleForgotPass}
            handleForgotPassword={handleForgotPassword}
          />
        ) : null}
        {/* {isRegisterForm ? (
          <RegisterForm
            show={isRegisterForm}
            toggleRegisterForm={toggleRegisterForm}
            handleRegisterForm={handleRegisterForm}
            handleLoader={(isOn) => setLoading(isOn)}
          />
        ) : null} */}
        {isRegisterForm ? (
          <SignupForm
            show={isRegisterForm}
            toggleRegisterForm={toggleRegisterForm}
            handleRegisterForm={handleRegisterForm}
            handleLoader={(isOn) => setLoading(isOn)}
          />
        ) : null}
        {/* <RegisterForm /> */}
        {isGuestLogin !== "true" && footerActive ? (
          <Footer
            footerActive={footerActive}
            setFooterActive={setFooterHandle}
          />
        ) : null}
      </>
    );
  }
};

export default STComeMapMain;
